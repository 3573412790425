import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledSectionHeader = styled(Flexbox)(
    ({theme: {palette}}) => css`
        width: 100%;
        color: ${palette.primary[900]};
    `
)

export const StyledTitle = styled.h3(
    ({theme: {typography}}) => css`
        ${typography.displaySm};
        font-weight: 500;
    `
)

export const StyledEnergy = styled.p(
    () => css`
        font-weight: 500;
    `
)

export const StyledPercentage = styled.p(
    ({theme: {typography}}) => css`
        ${typography.displaySm};
        font-weight: 600;
    `
)
