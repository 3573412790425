import axios from '../../../axiosInstance.ts'
import {UserType} from '@/features/auth/types.ts'
import {ChangePasswordPayloadType, EditProfilePayloadType} from '../types.ts'

export const PROFILE_API = {
    editProfile: async (data: EditProfilePayloadType): Promise<UserType> => {
        const url = '/users/profile'
        return await axios.patch(url, data)
    },
    changePassword: async (data: ChangePasswordPayloadType) => {
        const url = '/users/profile/change-password'
        return await axios.post(url, data)
    }
}
