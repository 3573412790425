import {RouterProvider} from 'react-router-dom'
import {StyleSheetManager, ThemeProvider} from 'styled-components'
import {getAppTheme, GlobalStyles} from '@/theme/theme.ts'
import router from './router.tsx'
import Toaster from './Toaster.tsx'
import {envVars} from '@/envVars.ts'
import {LoadScript, Libraries} from '@react-google-maps/api'

const libraries: Libraries = ['places']

export default function App() {
    const themeName = 'light' //get it from the app reducer
    return (
        <ThemeProvider theme={getAppTheme({name: themeName})}>
            <StyleSheetManager>
                <LoadScript googleMapsApiKey={envVars.VITE_GOOGLE_MAP_API_KEY ?? ''} libraries={libraries}>
                    <>
                        <Toaster />
                        <GlobalStyles />
                        <RouterProvider router={router} />
                    </>
                </LoadScript>
            </StyleSheetManager>
        </ThemeProvider>
    )
}
