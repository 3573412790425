import {useTranslation} from 'react-i18next'

import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {StyledItem, StyledResult, StyledSectionHeader, StyledSign, StyledTitle} from './style'
import {correctCurrency} from '@/utilities/constants'
import {formatValues} from '@/features/project-details/helpers/helpers'

type SavingDifferenceType = {
    first: number
    second: number
    result: number
}

export const FinancialImpactHeader = ({savingDifference}: {savingDifference: SavingDifferenceType}) => {
    const {t} = useTranslation()

    return (
        <StyledSectionHeader justify="space-between" align="center">
            <StyledTitle>{t('quote:financial_impact:title')}</StyledTitle>
            <Flexbox gap={4} align="center">
                <StyledItem direction="column" gap={1}>
                    <span>
                        {savingDifference.first} {correctCurrency}
                    </span>
                    <p>{t('quote:financial_impact:bill_value')}</p>
                </StyledItem>
                <StyledSign>-</StyledSign>
                <StyledItem direction="column" gap={1}>
                    <span>
                        {formatValues(savingDifference.second)} {correctCurrency}
                    </span>
                    <p>{t('quote:financial_impact:system_cost')}</p>
                </StyledItem>
                <StyledSign>=</StyledSign>
                <StyledResult direction="column" gap={1}>
                    <span>
                        {formatValues(savingDifference.result)} {correctCurrency}
                    </span>
                    <p>{t('quote:financial_impact:result')}</p>
                </StyledResult>
            </Flexbox>
        </StyledSectionHeader>
    )
}
