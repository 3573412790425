import React from 'react'
import styled, {css} from 'styled-components'
import {HomeIcon} from '@/features/quote/components/operation-system/icons/HomeIcon.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {ElectricityGridIcon} from '@/features/quote/components/operation-system/icons/ElectricityGridIcon.tsx'
import {SolarPanelIcon} from '@/features/quote/components/operation-system/icons/SolarPanelIcon.tsx'
import {BatteryIcon} from '@/features/quote/components/operation-system/icons/BatteryIcon.tsx'
import {ChargingStationIcon} from '@/features/quote/components/operation-system/icons/ChargingStationIcon.tsx'
import {QuoteCalculationsType, QuoteType} from '@/features/quote/types.ts'
import {useTranslation} from 'react-i18next'

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(3, auto);
    justify-items: center;
    align-items: center;
    max-width: 500px;
    margin: 0 auto;
    gap: 10px;

    .home {
        grid-area: 1 / 2 / 2 / 3;
    }

    .electricity-grid {
        grid-area: 2 / 3 / 3 / 4;
    }

    .battery {
        grid-area: 3 / 2 / 4 / 3;
    }

    .solar-energy {
        grid-area: 2 / 1 / 3 / 2;
    }

    .center-road {
        grid-area: 2 / 2 / 3 / 3;
    }
`

const Label = styled.div(
    ({theme: {typography, palette}}) => css`
        ${typography.textSm};
        font-weight: 500;
        color: ${palette.primary[700]};
    `
)

const EnergyText = styled.div(
    ({theme: {typography, palette}}) => css`
        ${typography.textSm};
        font-weight: 500;
        color: ${palette.primary[500]};
    `
)

export const BaseIcon = ({
    children,
    quoteCalculationsData,
    quoteData
}: {
    children: React.ReactNode
    quoteCalculationsData: QuoteCalculationsType
    quoteData: QuoteType['projectSystem']
}) => {
    const {t} = useTranslation()

    return (
        <GridContainer>
            <Flexbox className={'solar-energy'} direction={'column'} gap={2} align={'center'}>
                <SolarPanelIcon />
                <Label>{t('quote:operation_system:solar_energy')}</Label>
                <EnergyText>{quoteCalculationsData?.systemSize} kW</EnergyText>
            </Flexbox>

            <Flexbox className={'electricity-grid'} direction={'column'} gap={2} align={'center'} justify={'start'}>
                <ElectricityGridIcon />
                <Label>{t('quote:operation_system:electricity_grid')}</Label>
            </Flexbox>

            <Flexbox className={'home'} direction={'column'} gap={2} align={'center'}>
                <Label>{t('quote:operation_system:house')}</Label>
                <HomeIcon />
            </Flexbox>

            <Flexbox className={'battery'} gap={2} align={'flex-start'} style={{marginTop: 30}}>
                {quoteData.chargingStationProductId && <ChargingStationIcon />}
                {(quoteData.batteryProductId || quoteData.type === 'cerbero') && (
                    <Flexbox gap={2} align={'center'} direction={'column'}>
                        <BatteryIcon />
                        <Label>{t('quote:operation_system:battery')}</Label>
                        <EnergyText>
                            {quoteData.type === 'cerbero' ? '2,56' : quoteCalculationsData?.effectiveAccumulation} kW
                        </EnergyText>
                    </Flexbox>
                )}
            </Flexbox>

            <Flexbox className={'center-road'}>{children}</Flexbox>
        </GridContainer>
    )
}

export default BaseIcon
