import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'
import publicBG from '@/assets/images/login_background.png'
import bottomBG from '@/assets/images/bottom_bg.png'

export const StyledRoot = styled.div`
    height: 100%;
    position: relative;
    display: grid;
    background-image: url(${publicBG});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    margin: 0 auto;
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: -62px;
        right: 0;
        height: 300px;
        background-image: url(${bottomBG});
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        pointer-events: none;
        z-index: -1;
    }
`

export const StyledNotFoundContainer = styled(Flexbox)`
    ${({theme: {typography, palette}}) => css`
        height: 100%;
        width: 100%;
        max-width: 360px;
        margin: 0 auto;
        & h3 {
            ${typography.displayLg};
            text-align: center;
        }

        & p {
            ${typography.textMd};
            text-align: center;
            color: ${palette.primary[300]};
        }
    `}
`
