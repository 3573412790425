import {FC, useMemo} from 'react'
import {TooltipProps} from 'recharts'
import {StyledChartTooltipRoot} from './style'
import {useTranslation} from 'react-i18next'
import {correctCurrency} from '@/utilities/constants'

interface CustomTooltipProps extends TooltipProps<number, string> {
    amortizationIndex: number
}

export const CustomTooltip: FC<CustomTooltipProps> = ({active, payload, amortizationIndex}) => {
    const {t} = useTranslation()

    const tooltipText = useMemo(() => {
        if (payload && payload.length) {
            const value = payload[0].value

            switch (true) {
                case value && value < 0:
                    return t('quote:financial_impact:tooltip_negative')
                case value && value > 0:
                    return `${t('quote:financial_impact:tooltip_positive')} ${value} ${correctCurrency}`
                default:
                    return `${t('quote:financial_impact:tooltip_default', {INDEX: amortizationIndex})}`
            }
        }
        return null
    }, [payload, amortizationIndex])

    if (active && tooltipText) {
        return (
            <StyledChartTooltipRoot>
                <p>{tooltipText}</p>
            </StyledChartTooltipRoot>
        )
    }

    return null
}

CustomTooltip.displayName = 'CustomTooltip'
