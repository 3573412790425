import {z} from 'zod'
import {
    ProjectSchema,
    CreateProjectFormStepSchema,
    CreateProjectPayloadSchema,
    CREATE_PROJECT_MODEL,
    ProjectsResponseSchema,
    ElectricityConsumptionSchema,
    ProjectAnalyticsResponseSchema
} from './services/project.schema'
import {ProjectSystemsSchema} from '../project-details/services/project.schema'

export enum ProjectStatusesE {
    Draft = 'draft',
    UserApproval = 'user_approval',
    Confirmed = 'user_confirmed',
    Sale = 'on_sale',
    Completed = 'completed'
}

export enum ProjectTypologyE {
    Internal = 'internal',
    External = 'external'
}
export enum PlaceTypesE {
    Residential = 'residential',
    Commercial = 'commercial'
}

export enum SortProjectsE {
    SortByDate = 'createdAt',
    SortByTypology = 'type',
    SortByStatus = 'status'
}
export enum FormStepE {
    Form = 'form',
    Map = 'map'
}

export type LocationType = {
    lat: number
    lng: number
    alt?: number
}

export type ProjectType = z.infer<typeof ProjectSchema>
export type ProjectSystemType = z.infer<typeof ProjectSystemsSchema>
export type ProjectsResponseType = z.infer<typeof ProjectsResponseSchema>
export type CreateProjectFormStepType = z.infer<typeof CreateProjectFormStepSchema>
export type CreateProjectPayloadType = z.infer<typeof CreateProjectPayloadSchema>
export type EditProjectNamePayloadType = {[CREATE_PROJECT_MODEL.ProjectName]: string}
export type ElectricityConsumptionType = z.infer<typeof ElectricityConsumptionSchema>

export type ProjectsParamsType = {
    status?: ProjectStatusesE
    orderBy?: string
    orderDirection?: 'asc' | 'desc'
    companyId?: number | string
    page?: string
    limit: number
    search?: string
    selectedPeriod?: string
    responseType?: 'small' | 'extended'
    fromDate?: string
    refetchInterval?: number | false
}

export type ProjectAnalyticsParamType = {
    fromDate: string
    toDate: string
    companyId?: number | string
}
export type ProjectAnalyticsResponseType = z.infer<typeof ProjectAnalyticsResponseSchema>
