import {useTranslation} from 'react-i18next'
import {StyledButton, StyledModal} from './style'
import {useEffect, useState} from 'react'
import {ArrowLeftIcon} from '@/assets/icons/icons'
import {ChangePassword} from '../change-password/ChangePassword'
import {EditProfileForm} from '../edit-profile-form/EditProfileForm'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {useMe} from '@/features/auth/queries/useMe'
import {errorHandler} from '@/utilities/helpers'
import {Spinner} from '@/components/ui/spinner/Spinner'

type EditProfileProps = {
    onClose: () => void
}

export const EditProfileModal: React.FC<EditProfileProps> = ({onClose}) => {
    const {t} = useTranslation()
    const {data: user, isLoading, error, isError} = useMe()

    const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false)

    useEffect(() => {
        if (isError) {
            errorHandler({error: error})
        }
    }, [isError])

    const modalTitle = isChangePasswordModalOpen ? (
        <Flexbox align="center" gap={4}>
            <StyledButton onClick={() => setIsChangePasswordModalOpen(false)} variant="ghost">
                <ArrowLeftIcon />
                {t('commons:go_back')}
            </StyledButton>{' '}
            {t('edit_profile:change_password')}
        </Flexbox>
    ) : (
        t('edit_profile:title')
    )
    return (
        <StyledModal onClose={onClose} title={modalTitle} titleAlignment="start">
            {isLoading && <Spinner />}
            {isChangePasswordModalOpen ? (
                <ChangePassword onBack={() => setIsChangePasswordModalOpen(false)} />
            ) : (
                !!user && (
                    <EditProfileForm
                        onClose={onClose}
                        openChangePasswordModal={() => setIsChangePasswordModalOpen(true)}
                        user={user}
                    />
                )
            )}
        </StyledModal>
    )
}
EditProfileModal.displayName = 'EditProfileModal'
