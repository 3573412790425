import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {spacing} from '@/theme/theme'

export const StyledFinancialImpactRoot = styled(Flexbox)(
    () => css`
        padding: ${spacing * 14}px;
        height: 100%;
        width: 100%;
    `
)
