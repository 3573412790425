import {StyledSmall, StyledSmallError} from '@components/ui/input-help-text/style.ts'

interface InputHelpTextProps {
    error?: string
    helpText?: string
}
export const InputHelpText = ({error, helpText}: InputHelpTextProps) => {
    return (
        <>
            {error && <StyledSmallError>{error}</StyledSmallError>}
            {helpText && !error && <StyledSmall>{helpText}</StyledSmall>}
        </>
    )
}

InputHelpText.displayName = 'InputHelpText'
