import styled, {css} from 'styled-components'

type StyledInputStatusIconProps = {
    $hasError?: boolean
}
export const InputStatusIconWrapper = styled.div<StyledInputStatusIconProps>`
    ${({theme: {spacing}}) => css`
        position: absolute;
        top: 55%;
        transform: translateY(-50%);
        right: ${spacing * 2}px;
        display: grid;
        align-items: center;
    `}
`
export const StyledVisibilityToggle = styled.span`
    height: 100%;
    max-height: 24px;
    cursor: pointer;
`
