import {XCloseIcon} from '@/assets/icons/icons'
import styled, {css, DefaultTheme} from 'styled-components'

export const StyledToastContainer = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        gap: ${spacing * 8}px;
    `
)

export const StyledCloseIcon = styled(XCloseIcon)<{$variant: 'success' | 'error'}>`
    cursor: pointer;
    ${({$variant, theme}) => ColorStyles(theme)[$variant]}
`

const ColorStyles = (theme: DefaultTheme) => ({
    success: css`
        & path {
            stroke: ${theme.palette.primary['500']};
        }
    `,
    error: css`
        & path {
            stroke: ${theme.palette.danger['500']};
        }
    `
})
