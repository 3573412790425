import {useLayoutEffect} from 'react'

export const useLockBodyScroll = ({enable = true}: {enable?: boolean}) => {
    useLayoutEffect(() => {
        const originalStyle = window.getComputedStyle(document.body).overflow
        const originalWebkitOverflowScrolling = window
            .getComputedStyle(document.body)
            .getPropertyValue('-webkit-overflow-scrolling')

        if (enable && originalStyle !== 'hidden') {
            document.body.style.overflow = 'hidden'
            document.body.style.touchAction = 'none'

            return () => {
                document.body.style.overflow = originalStyle
                document.body.style.touchAction = originalWebkitOverflowScrolling
            }
        }
    }, [enable])
}
