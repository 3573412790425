import {
    StyledBillingData,
    StyledContacts,
    StyledDataTitle,
    StyledFirstRowQuoteTitle,
    StyledProposalRow,
    StyledQRCodeWrapper,
    StyledQuoteData,
    StyledQuoteDates,
    StyledQuoteName,
    StyledQuoteNumber,
    StyledQuoteSummary,
    StyledSummaryDescription,
    StyledSummaryNumber,
    StyledThanks
} from '@/features/quote/components/summary-quote/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useTranslation} from 'react-i18next'
import {QuoteType} from '../../types'
import moment from 'moment'
import {correctCurrency} from '@/utilities/constants'
import QRCode from 'react-qr-code'
import {envVars} from '@/envVars.ts'
import {useLocation} from 'react-router-dom'

export const SummaryQuote = ({
    quoteData,
    systemSize,
    quoteFinalPrice,
    estimatedAnnualSavings
}: {
    quoteData?: QuoteType
    systemSize?: number
    quoteFinalPrice?: string
    estimatedAnnualSavings?: number
}) => {
    const {t} = useTranslation()
    const projectData = quoteData?.project

    const link = `${envVars.VITE_FRONTEND_URL}/quote/${quoteData?.id}`

    const location = useLocation()
    const isPdf = location.search.includes('pdf')

    return (
        <StyledProposalRow>
            <Flexbox justify={'space-between'} align={'center'}>
                <StyledQuoteData direction={'column'} gap={4}>
                    <StyledFirstRowQuoteTitle>{t('quote:summary:title')}</StyledFirstRowQuoteTitle>

                    <Flexbox direction={'column'}>
                        <StyledQuoteDates>
                            {t('quote:summary:date')} {moment(quoteData?.createdAt).format('DD/MM/YY')}
                        </StyledQuoteDates>

                        <StyledQuoteDates>
                            {t('quote:summary:valid_until')} {moment(quoteData?.expiresAt).format('DD/MM/YY')}
                        </StyledQuoteDates>
                    </Flexbox>
                </StyledQuoteData>

                {isPdf && (
                    <StyledQRCodeWrapper>
                        <QRCode value={link} viewBox={`0 0 256 256`} />

                        <p>{t('quote:summary:qr_label')}</p>
                    </StyledQRCodeWrapper>
                )}
            </Flexbox>

            <StyledBillingData>
                <Flexbox direction={'column'} gap={1}>
                    <StyledDataTitle>{t('quote:summary:prepared_by')}</StyledDataTitle>
                    <StyledQuoteName>
                        {projectData?.user?.firstName} {projectData?.user?.lastName}
                    </StyledQuoteName>

                    <Flexbox gap={1}>
                        <StyledContacts>{t('commons:email') + ':'}</StyledContacts>
                        <StyledContacts>{projectData?.email}</StyledContacts>
                    </Flexbox>

                    <Flexbox gap={1}>
                        <StyledContacts>{t('commons:phone') + ':'}</StyledContacts>
                        <StyledContacts>{projectData?.phoneNumber}</StyledContacts>
                    </Flexbox>
                </Flexbox>

                <Flexbox direction={'column'} gap={1}>
                    <StyledDataTitle>{t('projects:client') + ':'}</StyledDataTitle>
                    <StyledQuoteName>
                        {' '}
                        {projectData?.firstName} {projectData?.lastName}
                    </StyledQuoteName>
                    <StyledContacts>{projectData?.address}</StyledContacts>
                </Flexbox>
            </StyledBillingData>

            <StyledThanks>
                <StyledQuoteNumber>
                    {t('quote:summary:dear')} {projectData?.firstName}
                </StyledQuoteNumber>
                <StyledQuoteDates>{t('quote:summary:thanks')}</StyledQuoteDates>
                <StyledQuoteDates>
                    {t('quote:summary:best_regards')}, {projectData?.user?.firstName}
                </StyledQuoteDates>
            </StyledThanks>

            <StyledQuoteSummary>
                <div>
                    <StyledSummaryNumber>
                        {systemSize} {t('commons:kilowatt')}{' '}
                    </StyledSummaryNumber>
                    <StyledSummaryDescription>{t('quote:summary:system_dimension')}</StyledSummaryDescription>
                </div>
                <div>
                    <StyledSummaryNumber>
                        {quoteFinalPrice} {correctCurrency}
                    </StyledSummaryNumber>
                    <StyledSummaryDescription>{t('quote:summary:net_price')}</StyledSummaryDescription>
                </div>
                <div>
                    <StyledSummaryNumber>
                        {estimatedAnnualSavings} {correctCurrency}
                    </StyledSummaryNumber>
                    <StyledSummaryDescription>{t('quote:summary:savings_estimate')}</StyledSummaryDescription>
                </div>
            </StyledQuoteSummary>
        </StyledProposalRow>
    )
}
