import {Toaster as RHTToaster} from 'react-hot-toast'
import {useTheme} from 'styled-components'
import {ErrorToastIcon, SuccessToastIcon} from './assets/icons/icons'

const Toaster = () => {
    const theme = useTheme()
    return (
        <RHTToaster
            position="top-right"
            toastOptions={{
                duration: 2000,
                style: {
                    padding: `${theme.spacing * 2}px`,
                    boxShadow: theme.shadows.md,
                    backgroundColor: theme.palette.neutral.white,
                    border: '1px solid',
                    fontWeight: 600
                },
                success: {
                    style: {
                        backgroundColor: theme.palette.primary[800],
                        borderColor: theme.palette.primary[700],
                        color: theme.palette.primary[300]
                    },
                    icon: <SuccessToastIcon />
                },
                error: {
                    style: {
                        backgroundColor: theme.palette.danger[950],
                        borderColor: theme.palette.danger[400],
                        color: theme.palette.danger[400]
                    },
                    icon: <ErrorToastIcon />
                }
            }}
        />
    )
}

export default Toaster
