export const SolarPanelIcon = () => {
    return (
        <svg width="108" height="108" viewBox="0 0 108 108" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_2827_51029)">
                <rect x="4" width="100" height="100" rx="24" fill="url(#paint0_linear_2827_51029)" />
                <rect x="5" y="1" width="98" height="98" rx="23" stroke="#6CB691" stroke-width="2" />
                <path
                    d="M46.6248 40.1504C46.6248 38.1413 47.4229 36.2146 48.8435 34.7939C50.2642 33.3733 52.191 32.5752 54.2 32.5752C56.2091 32.5752 58.1359 33.3733 59.5565 34.7939C60.9771 36.2146 61.7752 38.1413 61.7752 40.1504M54.2 47.7256V67.9261V75.5014M71.8755 57.8259H36.5245M54.2 75.5014H59.2502M54.2 75.5014H49.1499M54.2 26.2625V25M68.0879 40.1504H69.3504M39.0496 40.1504H40.3121M64.02 30.3279L64.9114 29.4365M43.4887 29.4391L44.38 30.3304M63.7776 47.7256H44.6224C42.1655 47.7256 40.9358 47.7256 39.994 48.3998C39.0547 49.074 38.6229 50.2633 37.7593 52.6419L35.9286 57.692C34.2419 62.3407 33.3985 64.6663 34.4893 66.295C35.5827 67.9261 37.984 67.9261 42.7892 67.9261H65.6108C70.416 67.9261 72.8173 67.9261 73.9107 66.295C75.0015 64.6663 74.1582 62.3407 72.4714 57.692L70.6407 52.6419C69.7772 50.2633 69.3454 49.074 68.4035 48.3998C67.4667 47.7256 66.237 47.7256 63.7776 47.7256Z"
                    stroke="white"
                    stroke-width="2.40476"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_2827_51029"
                    x="0"
                    y="0"
                    width="108"
                    height="108"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2827_51029" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2827_51029" result="shape" />
                </filter>
                <linearGradient
                    id="paint0_linear_2827_51029"
                    x1="3.99844"
                    y1="99.9994"
                    x2="103.999"
                    y2="-0.00121755"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#1E2322" />
                    <stop offset="1" stop-color="#5F937D" />
                </linearGradient>
            </defs>
        </svg>
    )
}
