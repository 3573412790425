import styled from 'styled-components'
import publicBG from '@/assets/images/login_background.png'
import bottomBG from '@/assets/images/bottom_bg.png'

export const StyledPublicLayout = styled.div`
    height: 100%;
    position: relative;
    display: grid;
    background-image: url(${publicBG});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    margin: 0 auto;
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: -62px;
        right: 0;
        height: 300px;
        background-image: url(${bottomBG});
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        pointer-events: none;
        z-index: -1;
    }
`
