import {XCloseIcon} from '@/assets/icons/icons'
import {
    StyledModal,
    StyledModalBody,
    StyledModalHeader,
    StyledModalOverlay,
    StyledModalTitle
} from '@components/ui/modal/modal-atoms/style.ts'
import {HTMLAttributes} from 'react'

export const ModalOverlay = (props: HTMLAttributes<HTMLDivElement>) => <StyledModalOverlay {...props} />
export const ModalMain = ({width, ...props}: HTMLAttributes<HTMLDivElement> & {width: string}) => (
    <StyledModal $width={width} {...props} />
)
export const ModalHeader = (props: HTMLAttributes<HTMLDivElement>) => <StyledModalHeader {...props} />
export const ModalBody = (props: HTMLAttributes<HTMLDivElement>) => <StyledModalBody {...props} />
export const ModalTitle = ({
    titleAlignment,
    ...props
}: HTMLAttributes<HTMLHeadingElement> & {titleAlignment?: string}) => (
    <StyledModalTitle $titleAlignment={titleAlignment} {...props} />
)
export const ModalXCloseButton = (props: HTMLAttributes<SVGSVGElement>) => <XCloseIcon {...props} />
