import styled, {css} from 'styled-components'
import {CSSProperties} from 'react'

export const StyledModalOverlay = styled.div(
    ({theme: {palette, zIndex}}) => css`
        position: fixed;
        inset: 0;
        background: ${palette.neutral[700]};
        opacity: 0.8;
        z-index: ${zIndex.modalOverlay};
        display: grid;
        align-content: center;
        justify-content: center;
    `
)

export const StyledModal = styled.div<{$width?: CSSProperties['width']}>(
    ({$width, theme: {palette, zIndex, spacing}}) => css`
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: ${zIndex.modal};
        border-radius: 20px;
        background: ${palette.primary[950]};
        border: 1px solid ${palette.primary[700]};
        width: ${$width};
        max-height: calc(100% - ${spacing * 6}px);
        max-width: calc(100% - ${spacing * 6}px);
        display: grid;
        grid-template-rows: auto 1fr;
    `
)

export const StyledModalHeader = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 1fr ${spacing * 6}px;
        place-items: center;
        padding: ${spacing * 4}px ${spacing * 6}px 0;
        white-space: pre-wrap;
        position: relative;
        font-weight: 700;

        & svg {
            grid-column: 2;
            cursor: pointer;
        }
    `
)

export const StyledModalBody = styled.div(
    ({theme: {spacing, scrollbar}}) => css`
        padding: ${spacing * 6}px ${spacing * 6}px ${spacing * 4}px;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: ${spacing * 6}px;
        ${scrollbar}
    `
)

export const StyledModalTitle = styled.h3<{$titleAlignment?: string}>(
    ({theme: {typography, palette}, $titleAlignment = 'center'}) => css`
        ${typography.displaySm}
        grid-column: 1;
        margin-top: 0px;
        text-align: center;
        justify-self: ${$titleAlignment};
        font-weight: 500;
        color: ${palette.primary['100']};
    `
)
