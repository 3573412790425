import {t} from 'i18next'
import {MonthlyConsumptionResponseType, TimeRangeE} from '../types'
import {ProjectSystemType} from '@/features/project/types'

const MONTH_NAMES: {[key: number]: string} = {
    1: 'january',
    2: 'february',
    3: 'march',
    4: 'april',
    5: 'may',
    6: 'june',
    7: 'july',
    8: 'august',
    9: 'september',
    10: 'october',
    11: 'november',
    12: 'december'
}

const getEvenDaysItems = (arr?: MonthlyConsumptionResponseType[]) => {
    if (!arr) return []
    return arr.filter(item => item.day % 2 === 0) // Filter for items where the day is even
}

export const remappedChartData = (chartData?: MonthlyConsumptionResponseType[], chartVariant?: TimeRangeE) => {
    let formattedChartData

    switch (chartVariant) {
        case TimeRangeE.HalfYearly:
            formattedChartData = chartData?.slice(0, 6).map(entry => ({
                name: t(`months:${MONTH_NAMES[entry.month]}`),
                uv: entry.value
            }))
            break
        case TimeRangeE.Monthly:
            formattedChartData = chartData?.map(entry => ({
                name: t(`months:${MONTH_NAMES[entry.month]}`),
                uv: entry.value
            }))
            break
        case TimeRangeE.Daily:
            formattedChartData = getEvenDaysItems(chartData)?.map(entry => ({
                name: entry.day.toString(),
                uv: entry.value
            }))
            break
        default:
            formattedChartData = []
            break
    }

    return formattedChartData
}

export const formatValues = (value: number | string | null | undefined): string => {
    // Convert the value to a number
    const numericValue = typeof value === 'string' ? parseFloat(value) : value

    // Check if the value is null, undefined, or nan
    if (numericValue == null || isNaN(numericValue)) {
        return '' // Handle null, undefined, or nan
    }

    const isInteger = Number.isInteger(numericValue)

    const formattedValue = new Intl.NumberFormat('it-IT', {
        minimumFractionDigits: isInteger ? 0 : 2,
        maximumFractionDigits: 2
    }).format(numericValue)

    return formattedValue
}

export const formatControlledValues = (value: number | string | null | undefined): string => {
    // Convert the value to a number
    const numericValue = typeof value === 'string' ? parseFloat(value) : value

    // Check if the value is null, undefined, or NaN
    if (numericValue == null || isNaN(numericValue)) {
        return '' // Handle null, undefined, or NaN
    }

    // Directly return integers without formatting to avoid unwanted decimals
    if (Number.isInteger(numericValue)) {
        return numericValue.toString()
    }

    // Format decimal numbers
    const formattedValue = new Intl.NumberFormat('it-IT', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 4
    }).format(numericValue)

    return formattedValue
}

export const remapSystemOptions = (options: ProjectSystemType[]) => {
    return options?.map(({id, name, ...rest}) => {
        return {
            id,
            value: status,
            label: name,
            extraPayload: rest
        }
    })
}
