import {useLockBodyScroll} from '@/hooks/useLockBodyScroll'
import {DefaultNamespace} from 'i18next'
import {FC, ReactNode} from 'react'
import {createPortal} from 'react-dom'
import {ModalBody, ModalMain, ModalOverlay, ModalTitle, ModalXCloseButton} from './modal-atoms/ModalAtoms'
import {StyledModalHeader} from './modal-atoms/style'

interface ModalProps {
    children: ReactNode
    onClose: () => void
    lockBodyScroll?: boolean
    fullScreen?: boolean
    title: string | DefaultNamespace | ReactNode
    className?: string
    width?: string
    hideCloseButton?: boolean
    titleAlignment?: string
}

export const Modal: FC<ModalProps> = ({
    children,
    onClose,
    title,
    lockBodyScroll = true,
    className = '',
    width = '100%',
    hideCloseButton = false,
    titleAlignment
}) => {
    useLockBodyScroll({enable: lockBodyScroll})

    return createPortal(
        <>
            <ModalOverlay onClick={onClose} />
            <ModalMain width={width} className={className}>
                <StyledModalHeader>
                    <ModalTitle titleAlignment={titleAlignment}>{title}</ModalTitle>
                    {!hideCloseButton && <ModalXCloseButton data-color onClick={onClose} />}
                </StyledModalHeader>
                <ModalBody>{children}</ModalBody>
            </ModalMain>
        </>,
        document.body
    )
}

Modal.displayName = 'Modal'
