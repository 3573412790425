import {FC} from 'react'
import {BarProps, Rectangle} from 'recharts'
import {useTheme} from 'styled-components'

type Payload = {
    uv?: number
    pv?: number
}

type CustomBarProps = BarProps & {
    payload?: Payload
}

export const CustomBar: FC<CustomBarProps> = props => {
    const theme = useTheme()
    const {x, y, height, fill, payload, dataKey} = props

    const validX = x !== undefined ? Number(x) : 0
    const validY = y !== undefined ? Number(y) : 0
    const validHeight = height !== undefined ? Number(height) : 0

    const value = payload ? payload[dataKey as keyof Payload] : null // Use the specific type

    const barWidth = 32
    const radius = 10

    return (
        <>
            <Rectangle
                x={validX}
                y={validY}
                width={barWidth}
                height={validHeight}
                fill={fill}
                radius={[radius, radius, 0, 0]}
            />

            {value !== null && (
                <text
                    x={validX + barWidth / 2}
                    y={validY + 16}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fill={theme.palette.neutral.white}
                    fontSize={12}
                    fontWeight={500}
                >
                    {value}
                </text>
            )}
        </>
    )
}
