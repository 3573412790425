import {Navigate, Outlet, generatePath, useLocation} from 'react-router-dom'
import {useAuthStore} from '@/features/auth/store/useAuthStore'
import {ROUTE_HOME, ROUTE_INSTALLER_INVITATION} from '@/utilities/constants'
import {LocalStorageManager} from '@/utilities/localStorage'

export const PublicRoute = () => {
    const accessToken = useAuthStore(state => state.accessToken)
    const location = useLocation()

    if (location.pathname.includes('invitation')) {
        useAuthStore.getState().reset()
        LocalStorageManager.accessToken.remove()
        LocalStorageManager.refreshToken.remove()
    }

    const getCorrectRedirectPath = () => {
        const invitationUserToken = LocalStorageManager.invitationUserToken.get()

        switch (true) {
            case !!invitationUserToken:
                return generatePath(ROUTE_INSTALLER_INVITATION, {token: invitationUserToken})

            default:
                return ROUTE_HOME
        }
    }

    return accessToken ? <Navigate to={getCorrectRedirectPath()} /> : <Outlet />
}
