import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import {QuoteCalculationsType} from '@/features/quote/types.ts'

const TableContainer = styled.div`
    width: 100%;
    overflow-x: auto;
    margin: 20px 0;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #052e1c;
    font-size: 12px;
`

const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;
`

const TableHeader = styled.th`
    padding: 12px;
    background-color: #f4f4f4;
    color: #052e1c;
    text-align: center;
    border: 1px solid #ddd;
    font-weight: 500;
`

const TableRow = styled.tr`
    &:hover {
        background-color: #f1f1f1;
    }

    &:nth-child(even) {
        background-color: #fafafa;
    }
`

const TableCell = styled.td`
    padding: 12px;
    text-align: center;
    border: 1px solid #ddd;
    &:first-child {
        font-weight: 500;
    }
`

interface SolarTableProps {
    data: QuoteCalculationsType['periodSavingsData']
}

export const SolarTable: React.FC<SolarTableProps> = ({data}) => {
    const {t} = useTranslation()
    const headers = [
        t('quote:saving:headers:bimonthly'),
        t('quote:saving:headers:solar_energy_production'),
        t('quote:saving:headers:electricity_before_solar'),
        t('quote:saving:headers:network_compensation'),
        t('quote:saving:headers:bill_before_solar'),
        t('quote:saving:headers:bill_after_solar'),
        t('quote:saving:headers:estimated_savings')
    ]

    const months = [
        t('quote:saving:months:jan'),
        t('quote:saving:months:feb'),
        t('quote:saving:months:mar'),
        t('quote:saving:months:apr'),
        t('quote:saving:months:may'),
        t('quote:saving:months:jun'),
        t('quote:saving:months:jul'),
        t('quote:saving:months:aug'),
        t('quote:saving:months:sep'),
        t('quote:saving:months:oct'),
        t('quote:saving:months:nov'),
        t('quote:saving:months:dec')
    ]

    const mapPeriodToMonths = (period: string) => {
        const [start, end] = period.split('-').map(Number)
        const startMonth = months[start - 1]
        const endMonth = months[end - 1]
        return `${startMonth}-${endMonth}`
    }

    return (
        <TableContainer>
            <StyledTable>
                <thead>
                    <tr>
                        {headers.map((header, index) => (
                            <TableHeader key={index}>{header}</TableHeader>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell>{mapPeriodToMonths(row.period)}</TableCell>
                            <TableCell>{row.production}</TableCell>
                            <TableCell>{row.consumption}</TableCell>
                            <TableCell>{row.periodCompensation}</TableCell>
                            <TableCell>{row.billOld}</TableCell>
                            <TableCell>{row.billNew}</TableCell>
                            <TableCell>{row.estimatedSaving}</TableCell>
                        </TableRow>
                    ))}
                </tbody>
            </StyledTable>
        </TableContainer>
    )
}
