import axios from '../../../axiosInstance.ts'
import {
    AuthStateType,
    CreateInstallerProfilePayloadType,
    CreateResellerProfilePayloadType,
    LoginPayloadType,
    UserType
} from '../types.ts'

export const AUTH_API = {
    login: async (values: LoginPayloadType): Promise<AuthStateType> => {
        const {data} = await axios.post('/auth/login?responseType=extended', values)
        return data
    },
    logout: (): Promise<void> => {
        return axios.post('/auth/logout')
    },
    me: async (): Promise<UserType> => {
        const params = {
            responseType: 'extended'
        }
        const {data} = await axios.get('/users/profile', {params})
        // TODO fix user type and add parse
        // return UserSchema.parse(data)
        return data
    },
    createInstallerProfile: async (payload: CreateInstallerProfilePayloadType) => {
        const url = `/user-company-invitation-tokens/${payload.token}`
        const {data} = await axios.post(url, payload)
        return data
    },
    createResellerProfile: async (payload: CreateResellerProfilePayloadType) => {
        const url = `/company-invitation-tokens/${payload.token}`
        const {data} = await axios.post(url, payload)
        return data
    }
}
