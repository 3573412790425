import axios from '../../../axiosInstance.ts'
import {ChangePriceResponseType, ChangeQuotePricePayloadType, QuoteCalculationsType, QuoteType} from '../types.ts'

export const QUOTE_API = {
    getQuote: async (quoteId?: string): Promise<QuoteType> => {
        const url = `/quotes/${quoteId}`
        const params = {
            responseType: 'extended'
        }
        const {data} = await axios.get(url, {params})
        return data
    },
    getQuoteCalculations: async (quoteId?: string): Promise<QuoteCalculationsType> => {
        const url = `/quotes/${quoteId}/calculations`
        const params = {
            responseType: 'extended'
        }
        const {data} = await axios.get(url, {params})
        return data
    },
    updateQuotePrice: async (
        payload: ChangeQuotePricePayloadType,
        quoteId?: string
    ): Promise<ChangePriceResponseType> => {
        const url = `/quotes/${quoteId}/prices`
        const params = {
            responseType: 'extended'
        }
        const {data} = await axios.post(url, payload, {params})
        return data
    },
    downloadQuote: async (quoteId: string) => {
        const url = `/quotes/${quoteId}/pdf`
        const {data} = await axios.get(url, {responseType: 'blob'})
        return data
    }
}
