import styled, {css, DefaultTheme} from 'styled-components'
import {ButtonStyleProps} from '@components/ui/button/Button.tsx'

export type StyledButtonProps = {
    $fullWidth?: boolean
    $shape: NonNullable<ButtonStyleProps['shape']>
    $variant: NonNullable<ButtonStyleProps['variant']>
}

export const makeButtonBaseStyle = (
    theme: DefaultTheme,
    {$fullWidth, $shape, disabled}: Omit<StyledButtonProps, '$variant'> & {disabled: boolean}
) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-weight: 600;
    word-break: break-word;
    transition: ${theme.transition};
    ${$shape == 'rectangle' &&
    $fullWidth &&
    css`
        width: 100%;
    `}
    ${$shape == 'rectangle' &&
    css`
        height: max-content;
    `}
    ${$shape == 'square' &&
    css`
        flex-shrink: 0;
    `}
  ${disabled
        ? css`
              cursor: not-allowed;
          `
        : css`
              cursor: pointer;
              &:focus-visible {
                  box-shadow: ${`${theme.shadows.xs}, 0px 0px 0px 4px ${theme.palette.neutral['100']}`};
              }
          `}
`

export const makeButtonSizeStyle = (theme: DefaultTheme) =>
    ({
        rectangle: css`
            gap: ${theme.spacing * 2}px;
            padding: ${theme.spacing * 2.5}px ${theme.spacing * 4}px;
            ${theme.typography.textSm};
            & svg {
                width: 20px;
            }
        `,
        square: css`
            width: 40px;
            height: 40px;
            & svg {
                width: 20px;
                height: 20px;
            }
        `
    }) as const satisfies Record<NonNullable<StyledButtonProps['$shape']>, ReturnType<typeof css>>

export const makeButtonVariantStyle = (theme: DefaultTheme, disabled: boolean) =>
    ({
        primary: css`
            color: ${theme.palette.neutral.white};
            box-shadow:
                0px 1px 2px 0px rgba(16, 24, 40, 0.05),
                0px -2px 0px 0px rgba(16, 24, 40, 0.05) inset,
                0px 0px 0px 1px rgba(16, 24, 40, 0.18) inset;
            ${disabled
                ? css`
                      background: ${theme.palette.primary['900']};
                      color: ${theme.palette.primary['700']};
                      border: 1px solid ${theme.palette.primary['800']};
                  `
                : css`
                      background: ${theme.palette.primary['600']};
                      &:hover {
                          color: ${theme.palette.neutral.white};
                          background: ${theme.palette.primary['700']};
                      }
                      &:active,
                      &.active {
                          background: ${theme.palette.primary['800']};
                      }
                  `}
        `,
        primaryDanger: css`
            color: ${theme.palette.neutral.white};
            box-shadow:
                0px 1px 2px 0px #1018280d,
                0px -2px 0px 0px #1018280d inset,
                0px 0px 0px 1px #1018282e inset;

            ${disabled
                ? css`
                      background: ${theme.palette.danger['400']};
                  `
                : css`
                      background: ${theme.palette.danger['600']};
                      &:hover {
                          color: ${theme.palette.neutral.white};
                          background: ${theme.palette.danger['700']};
                      }
                      &:active,
                      &.active {
                          background: ${theme.palette.danger['800']};
                      }
                  `}
        `,
        secondary: css`
            background: ${theme.palette.primary[900]};
            border: 1px solid ${theme.palette.primary[200]};
            ${disabled
                ? css`
                      opacity: 0.5;
                  `
                : css`
                      color: ${theme.palette.neutral.white};
                      &:hover,
                      &:active,
                      &.active {
                          background: ${theme.palette.primary[800]};
                      }
                  `}
        `,
        ghost: css`
            background: inherit;
            ${disabled
                ? css`
                      color: ${theme.palette.primary[800]};
                  `
                : css`
                      color: ${theme.palette.neutral[300]};
                      &:hover {
                          color: ${theme.palette.neutral.white};
                      }
                      &:active,
                      &.active {
                          background: ${theme.palette.neutral['100']}${theme.opacities[90]};
                      }
                  `}
        `,
        tertiary: css`
            background: ${theme.palette.neutral['900']};
            color: ${theme.palette.neutral[300]};
            border: 1px solid ${theme.palette.neutral['700']};
            ${disabled
                ? css`
                      opacity: 0.5;
                  `
                : css`
                      &:hover {
                          color: ${theme.palette.neutral.white};
                          background: ${theme.palette.neutral['800']};
                      }
                      &:active,
                      &.active {
                          background: ${theme.palette.neutral['100']}${theme.opacities[90]};
                      }
                  `}
        `,
        outlined: css`
            background: ${theme.palette.neutral['950']};
            color: ${theme.palette.neutral.white};
            border: 2px solid ${theme.palette.primary['200']};
            ${disabled
                ? css`
                      opacity: 0.5;
                  `
                : css`
                      &:hover {
                          color: ${theme.palette.neutral[300]};
                          background: ${theme.palette.neutral['950']};
                      }
                      &:active,
                      &.active {
                          background: ${theme.palette.neutral['950']};
                      }
                  `}
        `
    }) as const satisfies Record<NonNullable<StyledButtonProps['$variant']>, ReturnType<typeof css>>

export const StyledButton = styled.button<StyledButtonProps & {disabled?: boolean}>(
    ({theme, ...props}) => css`
        ${makeButtonSizeStyle(theme)[props.$shape]};
        ${makeButtonVariantStyle(theme, !!props.disabled)[props.$variant]};
        ${makeButtonBaseStyle(theme, {
            $fullWidth: props.$fullWidth,
            $shape: props.$shape,
            disabled: !!props.disabled
        })};
    `
)
