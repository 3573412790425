import {CSSProperties, forwardRef, HTMLInputTypeAttribute, InputHTMLAttributes, ReactNode, useState} from 'react'
import {StyledInput, StyledInputText, StyledInputWrapper} from '@components/commons/input-text/style.ts'
import {Label} from '@components/ui/label/Label.tsx'
import {InputStatusIcon} from '@components/ui/input-status-icon/InputStatusIcon.tsx'
import {InputHelpText} from '@components/ui/input-help-text/InputHelpText.tsx'

export interface InputTextProps extends InputHTMLAttributes<HTMLInputElement> {
    className?: string
    label?: ReactNode
    name?: string
    type: HTMLInputTypeAttribute
    typeIcon?: ReactNode
    touched?: boolean
    errorMessage?: string
    rightComponent?: ReactNode
    helpText?: string
    placeholder?: string
    width?: CSSProperties['width']
    visibilityToggle?: boolean
}

export const InputText = forwardRef<HTMLInputElement, InputTextProps>(
    (
        {
            label,
            name,
            type,
            typeIcon,
            rightComponent,
            touched = false,
            visibilityToggle = false,
            errorMessage,
            helpText,
            className,
            disabled,
            placeholder = '',
            width = '100%',
            ...rest
        },
        ref
    ) => {
        const [eyeVisible, setEyeVisible] = useState(false)

        return (
            <StyledInputText className={className} $width={width} direction="column" gap={1.5}>
                {label && <Label htmlFor={name}>{label}</Label>}

                <StyledInputWrapper
                    $hasError={!!errorMessage}
                    $visibilityToggle={visibilityToggle}
                    $touched={touched}
                    hasError={!!errorMessage}
                >
                    {typeIcon}
                    <StyledInput
                        id={`${name}`}
                        ref={ref}
                        name={name}
                        type={eyeVisible ? 'text' : type}
                        placeholder={placeholder}
                        disabled={disabled}
                        {...rest}
                    />
                    <InputStatusIcon
                        touched={touched}
                        hasError={!!errorMessage}
                        rightComponent={rightComponent}
                        visibilityToggle={visibilityToggle}
                        eyeVisible={eyeVisible}
                        setEyeVisible={setEyeVisible}
                    />
                </StyledInputWrapper>

                <InputHelpText error={errorMessage} helpText={helpText} />
            </StyledInputText>
        )
    }
)

InputText.displayName = 'InputText'
