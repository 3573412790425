import {StyledPerformanceChartRoot} from './style'
import {PerformanceHeader} from './performance-header/PerformanceHeader'
import {PerformanceChart} from './performance-chart/PerformanceChart'
import {QuoteCalculationsType} from '@/features/quote/types.ts'

export const PerformanceChartSection = ({quoteCalculationsData}: {quoteCalculationsData: QuoteCalculationsType}) => {
    return (
        <StyledPerformanceChartRoot direction="column" align="center" gap={4}>
            <PerformanceHeader quoteCalculationsData={quoteCalculationsData} />
            <PerformanceChart quoteCalculationsData={quoteCalculationsData} />
        </StyledPerformanceChartRoot>
    )
}
