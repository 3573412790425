import {CompanySchema} from '@/features/auth/services/auth.schema'
import {UserRoleE} from '@/utilities/constants'
import {z} from 'zod'

export const UserCompanySchema = z.object({
    id: z.string(),
    email: z.string().email(),
    // TODO ask BE if phoneNumber or name can be null
    phoneNumber: z.string().nullable(),
    qualification: z.string().nullable(),
    vatNumber: z.string().nullable(),
    name: z.string().nullable(),
    logoImagePath: z.string().nullable(),
    address: z.string().nullable(),
    roleId: z.string(),
    companyId: z.string(),
    createdAt: z.string(),
    updatedAt: z.string(),
    firstName: z.string().nullable(),
    lastName: z.string().nullable(),
    parentCompanyId: z.string().nullable()
})

export const CompanyInstallerProfileSchema = z.object({
    deletedAt: z.string().nullable(),
    id: z.string(),
    type: z.nativeEnum(UserRoleE),
    parentCompanyId: z.string().nullable(),
    userId: z.string(),
    name: z.string(),
    address: z.string(),
    logoImagePath: z.string(),
    vatNumber: z.string(),
    phoneNumber: z.string(),
    email: z.string(),
    createdAt: z.string(),
    updatedAt: z.string(),
    // TODO ask BE add this fields
    projectsTotalCount: z.number(),
    projectsConfirmedCount: z.number(),
    projectsTotalAmount: z.number()
})

export const CompanyProfileSchema = z.object({
    id: z.string(),
    type: z.string(),
    parentCompanyId: z.string().nullable(),
    userId: z.string(),
    name: z.string(),
    address: z.string(),
    logoImagePath: z.null(),
    vatNumber: z.string(),
    phoneNumber: z.string(),
    createdAt: z.string(),
    updatedAt: z.string(),
    logoImageUrl: z.null(),
    parentCompany: CompanySchema.nullable(),
    users: z.array(UserCompanySchema),
    installerOfCompanies: z.array(CompanySchema).nullable(),
    companyInstallers: z.array(CompanyInstallerProfileSchema).nullable(),
    owner: UserCompanySchema
})
