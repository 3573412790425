import {useTranslation} from 'react-i18next'
import {StyledSvgBox, StyledSvg, RotatingLine, StyledOverlay, StyledText} from './style'

interface SpinnerProps {
    color?: string
    staticCircleColor?: string
    size?: number
    zIndex?: number
    overlayFullscreen?: boolean
    overlay?: boolean
    inline?: boolean
    center?: boolean
    text?: boolean
}

export const Spinner = ({
    color = '#6CB691',
    staticCircleColor = '#1E2322',
    size = 66,
    overlayFullscreen = false,
    overlay = true,
    inline = false,
    text = true,
    center = true
}: SpinnerProps) => {
    const {t} = useTranslation()
    return (
        <>
            {overlay && <StyledOverlay $overlayFullscreen={!inline || overlayFullscreen} />}
            <StyledSvgBox $size={size} $center={center} $inline={inline}>
                <StyledSvg $size={size} viewBox="0 0 66 66">
                    <circle
                        cx="33"
                        cy="33"
                        r="29"
                        fill="none"
                        stroke={staticCircleColor}
                        strokeWidth="8"
                        strokeLinecap="round"
                    />
                </StyledSvg>
                <StyledSvg $size={size} viewBox="0 0 66 66">
                    <RotatingLine
                        d="M33 4 A29 29 0 0 1 62 33"
                        fill="none"
                        stroke={color}
                        strokeWidth="8"
                        strokeLinecap="round"
                    />
                </StyledSvg>
                {text && <StyledText $size={size}>{t('commons:loading')}</StyledText>}
            </StyledSvgBox>
        </>
    )
}
