export const ElectricityGridIconPdf = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="67" height="67" viewBox="0 0 67 67" fill="none">
            <g filter="url(#filter0_d_4506_58744)">
                <rect
                    x="2.69629"
                    y="0.819824"
                    width="61.1336"
                    height="61.1336"
                    rx="14.6721"
                    fill="url(#paint0_linear_4506_58744)"
                />
                <rect
                    x="3.30763"
                    y="1.43116"
                    width="59.9109"
                    height="59.9109"
                    rx="14.0607"
                    stroke="#6CB691"
                    stroke-width="1.22267"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M33.2629 17.3259C33.052 17.3259 32.8538 17.425 32.7266 17.5938L28.7092 22.9502C28.6215 23.0681 28.578 23.208 28.5773 23.3493C28.5766 23.3499 28.5766 23.3513 28.576 23.352V24.9817L22.2058 28.8042C22.0016 28.926 21.8844 29.145 21.8831 29.374C21.8777 30.2658 21.8804 31.1624 21.8804 32.0562C21.8804 32.4258 22.1803 32.7258 22.5499 32.7258C22.9195 32.7258 23.2195 32.4258 23.2195 32.0562V30.0475H28.576V36.5851L24.8151 44.1083H19.8717C19.5021 44.1083 19.2021 44.4082 19.2021 44.7778C19.2021 45.1474 19.5021 45.4474 19.8717 45.4474H46.654C47.0236 45.4474 47.3236 45.1474 47.3236 44.7778C47.3236 44.4082 47.0236 44.1083 46.654 44.1083H41.7107L37.9498 36.5851V30.0475H43.3063V32.0562C43.3063 32.4258 43.6062 32.7258 43.9758 32.7258C44.3454 32.7258 44.6454 32.4258 44.6454 32.0562V29.378C44.642 29.0941 44.4244 28.9562 44.32 28.8042L37.9498 24.9817V23.352C37.9491 23.3506 37.9491 23.3493 37.9484 23.3479C37.9478 23.2073 37.9036 23.0674 37.8165 22.9502L33.7992 17.5938C33.672 17.425 33.4738 17.3259 33.2629 17.3259ZM33.2629 19.111L35.9411 22.6824H30.5846L33.2629 19.111ZM29.9151 24.0215H36.6107V25.0024L33.2629 27.2347L29.9151 25.0024V24.0215ZM28.576 26.5431V28.7084H24.9664L28.576 26.5431ZM37.9498 26.5431L41.5594 28.7084H37.9498V26.5431ZM29.9151 26.612L32.0557 28.0389L29.9151 29.4657V26.612ZM36.6107 26.612V29.4657L34.4701 28.0389L36.6107 26.612ZM33.2629 28.843L36.073 30.7171L33.2629 32.5912L30.4527 30.7171L33.2629 28.843ZM29.9151 31.9685L32.0557 33.3953L29.9151 34.8222V31.9685ZM36.6107 31.9685V34.8222L34.4701 33.3953L36.6107 31.9685ZM33.2629 34.1995L36.5752 36.4083L33.2629 38.6172L29.9506 36.4083L33.2629 34.1995ZM29.506 37.7213L32.0557 39.4214L26.9543 42.8214L29.506 37.7213ZM37.0198 37.7213L39.5715 42.8214L34.4701 39.4214L37.0198 37.7213ZM33.2629 40.2255L39.086 44.1083H27.4397L33.2629 40.2255Z"
                    fill="white"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_4506_58744"
                    x="0.250945"
                    y="0.819824"
                    width="66.0245"
                    height="66.0242"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2.44534" />
                    <feGaussianBlur stdDeviation="1.22267" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4506_58744" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4506_58744" result="shape" />
                </filter>
                <linearGradient
                    id="paint0_linear_4506_58744"
                    x1="2.69534"
                    y1="61.9531"
                    x2="63.8292"
                    y2="0.81908"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#1E2322" />
                    <stop offset="1" stop-color="#5F937D" />
                </linearGradient>
            </defs>
        </svg>
    )
}
