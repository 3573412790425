import {Button} from '@/components/ui/button/Button'
import {Modal} from '@/components/ui/modal/Modal'
import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {StyledButtonWrapper, StyledDescription} from './style'
import {useLogout} from '../../queries/useLogout'
import {errorHandler} from '@/utilities/helpers'
import {Spinner} from '@/components/ui/spinner/Spinner'

type LogoutModalProps = {
    onClose: () => void
}

export const LogoutModal: React.FC<LogoutModalProps> = ({onClose}) => {
    const {t} = useTranslation()
    const {mutate: logout, isPending, isError, error} = useLogout(onClose)

    useEffect(() => {
        if (isError) {
            errorHandler({error})
        }
    }, [isError])

    return (
        <Modal onClose={onClose} title={t('logout_modal:title')} titleAlignment="start" width="fit-content">
            {isPending && <Spinner />}
            <StyledDescription>{t('logout_modal:description')}</StyledDescription>

            <StyledButtonWrapper>
                <Button onClick={() => logout()}>{t('logout_modal:confirm_button')}</Button>
                <Button variant="tertiary" onClick={onClose}>
                    {t('commons:cancel')}
                </Button>
            </StyledButtonWrapper>
        </Modal>
    )
}

LogoutModal.displayName = 'LogoutModal'
