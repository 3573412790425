import React, {LabelHTMLAttributes} from 'react'
import {StyledLabel} from '@components/ui/label/style.ts'

interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
    children: React.ReactNode
    htmlFor?: string
    hasError?: boolean
}

export const Label = ({children, htmlFor, hasError}: LabelProps) => (
    <StyledLabel htmlFor={htmlFor} $hasError={hasError}>
        {children}
    </StyledLabel>
)
