import styled, {css, keyframes, DefaultTheme} from 'styled-components'

const rotateAroundCircle = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

export const StyledSvgBox = styled.div<{
    $inline: boolean
    $size: number
    $center: boolean
}>`
    ${({$inline, $size, $center}) => css`
        position: ${$inline ? 'absolute' : 'fixed'};
        width: ${$size}px;
        height: ${$size}px;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        z-index: 14;
        ${$center &&
        css`
            left: 50%;
            margin-left: ${-$size / 2}px;
            top: 50%;
            margin-top: ${-$size / 2}px;
        `}

        ${$inline &&
        css`
            position: static;
        `}
    `}
`

export const StyledSvg = styled.svg<{
    $size: number
}>`
    ${({$size}) => css`
        width: ${$size}px;
        height: ${$size}px;
        position: absolute;
    `}
`

export const RotatingLine = styled.path`
    transform-origin: center;
    animation: ${rotateAroundCircle} 2s linear infinite;
`

export const StyledOverlay = styled.div<{
    $overlayFullscreen?: boolean
    theme: DefaultTheme
}>`
    ${({theme, $overlayFullscreen}) => css`
        position: ${$overlayFullscreen ? 'fixed' : 'absolute'};
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: ${theme.palette.primary[900]};
        opacity: 0.8;
        z-index: ${theme.zIndex.spinnerOverlay};
    `}
`
export const StyledText = styled.p<{
    $size: number
}>`
    ${({theme: {palette, typography}, $size}) => css`
        position: absolute;
        bottom: ${-$size / 2}px;
        color: ${palette.primary[300]};
        font-weight: 500;
        ${typography.textLg}
    `}
`
