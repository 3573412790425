import styled, {DefaultTheme, css} from 'styled-components'
import {InputWrapper} from '@/components/ui/input-wrapper/InputWrapper'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {CSSProperties} from 'react'

export const StyledInputText = styled(Flexbox)<{$width: CSSProperties['width']}>(
    ({$width}) => css`
        width: ${$width};
    `
)

export const StyledInputWrapper = styled(InputWrapper)<{
    theme: DefaultTheme
    $hasError: boolean
    $visibilityToggle: boolean
    $touched: boolean
}>`
    ${({theme: {spacing, palette}, $hasError, $visibilityToggle, $touched}) => css`
        padding: ${spacing * 2.5}px ${spacing * 3.5}px;
        &:has(input:placeholder-shown) {
            & section {
                color: ${palette.primary['500']};
            }
        }
        ${($hasError || $touched || $visibilityToggle) &&
        css`
            padding-right: ${spacing * 9}px;
        `}
    `};
`

export const StyledInput = styled.input`
    ${({theme: {typography, palette}}) => css`
        flex: 1;
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        ${typography.textMd}

        &:focus {
            outline: none;
        }
        &::placeholder {
            color: ${palette.primary['500']};
        }
        &:disabled {
            cursor: not-allowed;
        }
    `}
`
