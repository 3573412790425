import {forwardRef, InputHTMLAttributes, ReactNode} from 'react'
import {StyledCheckBox, StyledCheckBoxContainer, StyledCheckBoxInputWrapper, StyledCheckBoxLabel} from './style'
import {InputHelpText} from '@components/ui/input-help-text/InputHelpText.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
    id: string
    label?: ReactNode
    labelPosition?: 'left' | 'right'
    name?: string
    errorMessage?: string
    helpText?: string
    placeholder?: string
    containerClass?: string
    invisible?: boolean
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
    (
        {
            id,
            label,
            labelPosition = 'right',
            errorMessage,
            helpText,
            name,
            onChange,
            onBlur,
            containerClass,
            invisible,
            ...rest
        },
        ref
    ) => {
        return (
            <StyledCheckBoxContainer $label={label} className={containerClass}>
                <StyledCheckBoxInputWrapper $labelPosition={labelPosition}>
                    <StyledCheckBox
                        id={id}
                        type="checkbox"
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                        {...rest}
                        ref={ref}
                        $invisible={invisible}
                    />
                </StyledCheckBoxInputWrapper>
                <Flexbox direction={'column'} gap={2}>
                    <StyledCheckBoxLabel htmlFor={id}> {label} </StyledCheckBoxLabel>
                    <InputHelpText helpText={helpText} error={errorMessage} />
                </Flexbox>
            </StyledCheckBoxContainer>
        )
    }
)

Checkbox.displayName = 'Checkbox'
