export const SolarPanelIconPdf = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="67" height="67" viewBox="0 0 67 67" fill="none">
            <g filter="url(#filter0_d_4506_58742)">
                <rect
                    x="3"
                    y="0.819824"
                    width="61.1336"
                    height="61.1336"
                    rx="14.6721"
                    fill="url(#paint0_linear_4506_58742)"
                />
                <rect
                    x="3.61134"
                    y="1.43116"
                    width="59.9109"
                    height="59.9109"
                    rx="14.0607"
                    stroke="#6CB691"
                    stroke-width="1.22267"
                />
                <path
                    d="M29.0578 25.3653C29.0578 24.137 29.5458 22.9591 30.4142 22.0907C31.2827 21.2222 32.4606 20.7343 33.6888 20.7343C34.9171 20.7343 36.095 21.2222 36.9635 22.0907C37.8319 22.9591 38.3198 24.137 38.3198 25.3653M33.6888 29.9963V42.3456V46.9766M44.4945 36.1709H22.8832M33.6888 46.9766H36.7762M33.6888 46.9766H30.6015M33.6888 16.8751V16.1033M42.179 25.3653H42.9508M24.4269 25.3653H25.1987M39.6922 19.3604L40.2371 18.8155M27.1406 18.817L27.6855 19.3619M39.544 29.9963H27.8337C26.3317 29.9963 25.58 29.9963 25.0042 30.4084C24.4299 30.8206 24.166 31.5476 23.638 33.0018L22.5189 36.0891C21.4877 38.931 20.9721 40.3527 21.639 41.3484C22.3074 42.3456 23.7754 42.3456 26.713 42.3456H40.6647C43.6023 42.3456 45.0703 42.3456 45.7387 41.3484C46.4056 40.3527 45.89 38.931 44.8588 36.0891L43.7396 33.0018C43.2117 31.5476 42.9477 30.8206 42.372 30.4084C41.7993 29.9963 41.0475 29.9963 39.544 29.9963Z"
                    stroke="white"
                    stroke-width="1.47012"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_4506_58742"
                    x="0.554656"
                    y="0.819824"
                    width="66.0245"
                    height="66.0242"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2.44534" />
                    <feGaussianBlur stdDeviation="1.22267" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4506_58742" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4506_58742" result="shape" />
                </filter>
                <linearGradient
                    id="paint0_linear_4506_58742"
                    x1="2.99905"
                    y1="61.9531"
                    x2="64.1329"
                    y2="0.81908"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#1E2322" />
                    <stop offset="1" stop-color="#5F937D" />
                </linearGradient>
            </defs>
        </svg>
    )
}
