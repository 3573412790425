import {ZodType, z} from 'zod'

export const PageSchema = <T>(DataSchema: ZodType<T>) =>
    z.object({
        data: z.array(DataSchema),
        meta: z.object({
            currentPage: z.number(),
            firstPage: z.number(),
            firstPageUrl: z.string(),
            lastPage: z.number(),
            lastPageUrl: z.string(),
            nextPageUrl: z.nullable(z.string()),
            perPage: z.number(),
            previousPageUrl: z.nullable(z.string()),
            total: z.number()
        })
    })
