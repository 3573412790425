import styled, {css} from 'styled-components'
import bottomBG from '@/assets/images/bottom_bg.png'

export const StyledPrivateLayout = styled.div`
    ${({theme: {palette}}) => css`
        height: 100%;
        display: grid;
        position: relative;
        z-index: 2;
        background-color: ${palette.primary[900]};

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: -62px;
            right: 0;
            height: 300px;
            background-image: url(${bottomBG});
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            pointer-events: none;
            z-index: -1;
        }
    `}
`
