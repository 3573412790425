export const BatteryIconPdf = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="67" height="67" viewBox="0 0 67 67" fill="none">
            <g filter="url(#filter0_d_4506_58740)">
                <rect
                    x="3.1543"
                    y="0.131592"
                    width="61.1336"
                    height="61.1336"
                    rx="14.6721"
                    fill="url(#paint0_linear_4506_58740)"
                />
                <rect
                    x="3.76563"
                    y="0.742928"
                    width="59.9109"
                    height="59.9109"
                    rx="14.0607"
                    stroke="#6CB691"
                    stroke-width="1.22267"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M44.5661 22.3513C45.3384 22.3513 46.0779 22.658 46.6235 23.2036C47.169 23.7492 47.4758 24.4887 47.4758 25.261V40.7796C47.4758 41.5519 47.1691 42.2914 46.6235 42.837C46.0779 43.3826 45.3384 43.6893 44.5661 43.6893H23.2281C22.4558 43.6893 21.7162 43.3826 21.1707 42.837C20.6251 42.2914 20.3184 41.5519 20.3184 40.7796V25.261C20.3184 24.4887 20.6251 23.7492 21.1707 23.2036C21.7162 22.6581 22.4558 22.3513 23.2281 22.3513V21.3814C23.2281 19.7738 24.5302 18.4717 26.1378 18.4717H28.0776C29.6853 18.4717 30.9873 19.7738 30.9873 21.3814V22.3513H36.8068V21.3814C36.8068 19.7738 38.1089 18.4717 39.7165 18.4717H41.6563C43.264 18.4717 44.5661 19.7738 44.5661 21.3814V22.3513ZM42.6262 22.3513V21.3814C42.6262 20.8455 42.1922 20.4115 41.6563 20.4115H39.7165C39.1806 20.4115 38.7466 20.8455 38.7466 21.3814V22.3513H42.6262ZM29.0475 22.3513V21.3814C29.0475 20.8455 28.6135 20.4115 28.0776 20.4115H26.1378C25.6019 20.4115 25.1679 20.8455 25.1679 21.3814V22.3513H29.0475ZM44.5661 24.2911H23.2281C22.9711 24.2911 22.7237 24.393 22.5419 24.5748C22.36 24.7567 22.2582 25.004 22.2582 25.261V40.7796C22.2582 41.0366 22.36 41.2839 22.5419 41.4658C22.7237 41.6476 22.9711 41.7495 23.2281 41.7495H44.5661C44.8231 41.7495 45.0704 41.6476 45.2523 41.4658C45.4341 41.2839 45.536 41.0366 45.536 40.7796V25.261C45.536 25.004 45.4341 24.7567 45.2523 24.5748C45.0704 24.393 44.8231 24.2911 44.5661 24.2911ZM35.0926 26.5801C35.4345 26.1691 36.0468 26.1121 36.4578 26.4564C36.8687 26.7984 36.9257 27.4106 36.5814 27.8216L33.0581 32.0504H36.8067C37.1826 32.0504 37.5257 32.2674 37.6857 32.6093C37.8445 32.95 37.7936 33.3525 37.5511 33.641L32.7016 39.4605C32.3597 39.8715 31.7475 39.9285 31.3365 39.5841C30.9255 39.2422 30.8685 38.63 31.2128 38.219L34.7361 33.9902H30.9875C30.6116 33.9902 30.2685 33.7732 30.1085 33.4313C29.9497 33.0906 30.0006 32.6881 30.2431 32.3996L35.0926 26.5801Z"
                    fill="white"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_4506_58740"
                    x="0.708953"
                    y="0.131592"
                    width="66.0245"
                    height="66.0242"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2.44534" />
                    <feGaussianBlur stdDeviation="1.22267" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4506_58740" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4506_58740" result="shape" />
                </filter>
                <linearGradient
                    id="paint0_linear_4506_58740"
                    x1="3.15334"
                    y1="61.2648"
                    x2="64.2872"
                    y2="0.130847"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#1E2322" />
                    <stop offset="1" stop-color="#5F937D" />
                </linearGradient>
            </defs>
        </svg>
    )
}
