import {css} from 'styled-components'
import '@fontsource/ibm-plex-sans/index.css'
import '@fontsource/ibm-plex-sans/500.css'
import '@fontsource/ibm-plex-sans/600.css'
import '@fontsource/ibm-plex-sans/700.css'

export const defaultFontFamily = 'IBM Plex Sans, sans-serif'
export const secondaryFontFamily = 'IBM Plex Sans, condensed'

export const typography = {
    displayXl: css`
        font-family: ${defaultFontFamily};
        font-size: 40px;
        line-height: 56px;
        letter-spacing: -0.02em;
    `,
    displayLg: css`
        font-family: ${defaultFontFamily};
        font-size: 36px;
        line-height: 50px;
        letter-spacing: -0.02em;
    `,
    displayMd: css`
        font-family: ${defaultFontFamily};
        font-size: 30px;
        line-height: 44px;
        letter-spacing: -0.02em;
    `,
    displaySm: css`
        font-family: ${defaultFontFamily};
        font-size: 24px;
        line-height: 38px;
    `,
    displayXs: css`
        font-family: ${defaultFontFamily};
        font-size: 18px;
        line-height: 32px;
    `,
    textXl: css`
        font-family: ${defaultFontFamily};
        font-size: 20px;
        line-height: 30px;
    `,
    textLg: css`
        font-family: ${defaultFontFamily};
        font-size: 18px;
        line-height: 28px;
    `,
    textMd: css`
        font-family: ${defaultFontFamily};
        font-size: 16px;
        line-height: 24px;
    `,
    textSm: css`
        font-family: ${defaultFontFamily};
        font-size: 14px;
        line-height: 20px;
    `,
    textXs: css`
        font-family: ${defaultFontFamily};
        font-size: 12px;
        line-height: 18px;
    `
}
