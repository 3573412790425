import {Button} from '@/components/ui/button/Button'
import {Modal} from '@/components/ui/modal/Modal'
import styled from 'styled-components'

export const StyledModal = styled(Modal)`
    max-width: 742px;
    width: 100%;
`

export const StyledButton = styled(Button)`
    padding: 0px;
`
