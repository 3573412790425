import styled, {css} from 'styled-components'

export const StyledSmallError = styled.small`
    ${({theme: {palette, typography}}) => css`
        color: ${palette.danger['400']};
        ${typography.textSm}
    `}
`

export const StyledSmall = styled.small`
    ${({theme: {palette, typography}}) => css`
        color: ${palette.primary['500']};
        ${typography.textSm}
    `}
`
