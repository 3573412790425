import styled, {css} from 'styled-components'

export const StyledChartTooltipRoot = styled.div(
    ({theme: {typography, palette, spacing}}) => css`
        background-color: ${palette.neutral.white};
        color: ${palette.primary[700]};
        padding-left: ${spacing}px;
        padding-right: ${spacing}px;
        font-weight: 500;
        max-height: 20px;
        border-radius: 4px;
        border: 1px solid var(--Colors-Border-border-primary, ${palette.primary[300]});
        box-shadow:
            0px 1px 3px 0px rgba(16, 24, 40, 0.1),
            0px 1px 2px 0px rgba(16, 24, 40, 0.06);
        ${typography.textXs}
    `
)
