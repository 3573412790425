import {AlertCircleIcon, CheckCircleIcon, EyeClosedIcon, EyeOpenIcon} from '@/assets/icons/icons'
import {InputStatusIconWrapper, StyledVisibilityToggle} from '@components/ui/input-status-icon/style.ts'
import {Dispatch, ReactNode, SetStateAction} from 'react'
import {useTheme} from 'styled-components'

interface InputStatusIconProps {
    hasError?: boolean
    touched?: boolean
    isLoading?: boolean
    rightComponent?: ReactNode
    visibilityToggle?: boolean
    eyeVisible?: boolean
    setEyeVisible?: Dispatch<SetStateAction<boolean>> | null
}
export const InputStatusIcon = ({
    hasError,
    touched,
    rightComponent,
    visibilityToggle = false,
    eyeVisible = false,
    setEyeVisible = null
}: InputStatusIconProps) => {
    const {palette} = useTheme()
    return (
        <InputStatusIconWrapper>
            {!!rightComponent && !hasError && <section>{rightComponent}</section>}
            {visibilityToggle && !hasError && (
                <StyledVisibilityToggle onClick={() => !!setEyeVisible && setEyeVisible(!eyeVisible)}>
                    {eyeVisible ? <EyeOpenIcon /> : <EyeClosedIcon />}
                </StyledVisibilityToggle>
            )}
            {hasError && <AlertCircleIcon width={16} height={16} data-color fill={palette.danger[400]} />}
            {touched && !hasError && <CheckCircleIcon width={16} height={16} data-color />}
        </InputStatusIconWrapper>
    )
}

InputStatusIcon.displayName = 'InputStatusIcon'
