export const FixingIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="61" height="61" viewBox="0 0 61 61" fill="none">
            <g filter="url(#filter0_d_4243_29798)">
                <rect x="2.3335" width="56" height="56" rx="11.2" fill="url(#paint0_linear_4243_29798)" />
                <rect
                    x="2.8935"
                    y="0.56"
                    width="54.88"
                    height="54.88"
                    rx="10.64"
                    stroke="#6CB691"
                    stroke-width="1.12"
                />
                <path
                    d="M20.2059 41C19.9971 41 19.8225 40.9294 19.6824 40.7882C19.5422 40.6471 19.4716 40.4725 19.4706 40.2647V38.625H18V33.4779C18 33.2691 18.0706 33.0941 18.2118 32.9529C18.3529 32.8118 18.5275 32.7412 18.7353 32.7412H20.9412V21.1471C20.9412 19.7176 21.4417 18.5025 22.4426 17.5015C23.4436 16.5005 24.6588 16 26.0882 16C27.5176 16 28.7328 16.5005 29.7338 17.5015C30.7348 18.5025 31.2353 19.7176 31.2353 21.1471V35.8529C31.2353 36.8882 31.5892 37.7598 32.2971 38.4676C33.0049 39.1755 33.8765 39.5294 34.9118 39.5294C35.9471 39.5294 36.8186 39.1755 37.5265 38.4676C38.2343 37.7598 38.5882 36.8882 38.5882 35.8529V24.2588H36.3824C36.1735 24.2588 35.999 24.1882 35.8588 24.0471C35.7186 23.9059 35.648 23.7314 35.6471 23.5235V18.3765H37.1176V16.7353C37.1176 16.5265 37.1882 16.352 37.3294 16.2118C37.4706 16.0716 37.6451 16.001 37.8529 16H40.7941C41.0029 16 41.1775 16.0706 41.3176 16.2118C41.4578 16.3529 41.5284 16.5275 41.5294 16.7353V18.3765H43V23.5235C43 23.7314 42.9294 23.9059 42.7882 24.0471C42.6471 24.1882 42.4725 24.2588 42.2647 24.2588H40.0588V35.8529C40.0588 37.2824 39.5583 38.4975 38.5574 39.4985C37.5564 40.4995 36.3412 41 34.9118 41C33.4824 41 32.2672 40.4995 31.2662 39.4985C30.2652 38.4975 29.7647 37.2824 29.7647 35.8529V21.1471C29.7647 20.1118 29.4108 19.2402 28.7029 18.5324C27.9951 17.8245 27.1235 17.4706 26.0882 17.4706C25.0529 17.4706 24.1814 17.8245 23.4735 18.5324C22.7657 19.2402 22.4118 20.1118 22.4118 21.1471V32.7426H24.6176C24.8265 32.7426 25.001 32.8127 25.1412 32.9529C25.2814 33.0931 25.352 33.2676 25.3529 33.4765V38.6235H23.8824V40.2647C23.8824 40.4735 23.8118 40.648 23.6706 40.7882C23.5294 40.9284 23.3549 40.999 23.1471 41H20.2059Z"
                    fill="white"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_4243_29798"
                    x="0.0934961"
                    y="0"
                    width="60.48"
                    height="60.48"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2.24" />
                    <feGaussianBlur stdDeviation="1.12" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4243_29798" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4243_29798" result="shape" />
                </filter>
                <linearGradient
                    id="paint0_linear_4243_29798"
                    x1="2.33262"
                    y1="55.9997"
                    x2="58.3328"
                    y2="-0.000681827"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#1E2322" />
                    <stop offset="1" stop-color="#5F937D" />
                </linearGradient>
            </defs>
        </svg>
    )
}
