import {ButtonHTMLAttributes, forwardRef} from 'react'
import {StyledButton} from '@components/ui/button/style.ts'

export type ButtonStyleProps = {
    fullWidth?: boolean
    shape?: 'rectangle' | 'square'
    variant?: 'primary' | 'primaryDanger' | 'secondary' | 'ghost' | 'outlined' | 'tertiary'
    className?: string
}

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & ButtonStyleProps

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    ({fullWidth, shape = 'rectangle', variant = 'primary', className = '', ...props}, ref) => (
        <StyledButton
            ref={ref}
            $fullWidth={fullWidth}
            $shape={shape}
            $variant={variant}
            className={className}
            {...props}
        />
    )
)
Button.displayName = 'Button'
