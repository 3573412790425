import {ReactNode} from 'react'
import {StyledComponentCard, StyledImage} from './style'

export const ComponentCard = ({
    title,
    image,
    description
}: {
    title: string
    image: ReactNode
    description: string | ReactNode
}) => {
    return (
        <StyledComponentCard direction={'column'} gap={4}>
            <StyledImage>{image}</StyledImage>
            <h3 className={'title'}>{title}</h3>
            <p className={'description'}>{description}</p>
        </StyledComponentCard>
    )
}
ComponentCard.displayName = 'ComponentCard'
