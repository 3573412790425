import {palette as mainPalette} from './palette.ts'
import {breakpoints, mediaQueries} from './mediaQueries.ts'
import ResetStyles from './reset.ts'
import {shadows} from './shadows.ts'
import {opacities} from './opacities.ts'
import {DefaultTheme, css} from 'styled-components'
import {typography} from './typography.ts'

const GlobalStyles = ResetStyles
export const spacing = 4

interface ThemeInterface {
    name: 'light'
    /*name: 'light' | 'dark'*/
    direction?: 'ltr' | 'rtl'
}

const getAppTheme = ({name}: ThemeInterface): DefaultTheme => {
    const palette = mainPalette[name]
    const containerWidth = 1280
    const sideBarWidth = 280
    const truncateText = css`
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    `

    const scrollbar = css`
        &::-webkit-scrollbar {
            background: transparent;
            width: 8px;
            border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background: ${palette.primary[800]};
            border-radius: 9999px;
        }

        &::-webkit-scrollbar-track {
            margin-bottom: ${spacing * 4}px;
        }
        &::-webkit-scrollbar-button:start {
            display: none;
        }
        &::-webkit-scrollbar-button:end {
            display: none;
        }
    `

    return {
        breakpoints,
        mediaQueries,
        palette,
        shadows,
        opacities,
        spacing,
        transition: 'ease-in-out 200ms',
        typography,
        containerWidth,
        truncateText,
        sideBarWidth,
        scrollbar,
        zIndex: {
            modalOverlay: 10,
            modal: 11,
            modalDatePicker: 12,
            tooltip: 13,
            spinnerOverlay: 12
        }
    }
}

export {GlobalStyles, getAppTheme}
