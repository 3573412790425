import {useTranslation} from 'react-i18next'
import {toast} from '@utilities/toast/toast.tsx'
import {Button} from '@/components/ui/button/Button'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {SubmitHandler, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {StyledForm, StyledInputWrapper, StyledPasswordTitle} from './style'
import {CHANGE_PASSWORD_MODEL, ChangePasswordValidationSchema} from '../../services/profile.schema'
import {ChangePasswordPayloadType} from '../../types'
import {InputText} from '@/components/commons/input-text/InputText'
import {useEffect} from 'react'
import {errorHandler} from '@/utilities/helpers'
import {CheckIcon} from '@/assets/icons/icons'
import {useTheme} from 'styled-components'
import {useChangePassword} from '../../queries/useChangePassword'

type ChangePasswordProps = {
    onBack: () => void
}

export const ChangePassword: React.FC<ChangePasswordProps> = ({onBack}) => {
    const {t} = useTranslation()
    const {palette} = useTheme()

    const onSuccessEditProfile = () => {
        toast.success(t('change_password:success'))
        onBack()
    }

    const {mutate, isPending, isError, error} = useChangePassword({onSuccess: onSuccessEditProfile})

    const {
        register,
        handleSubmit,
        setError,
        formState: {errors}
    } = useForm<ChangePasswordPayloadType>({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        resolver: zodResolver(ChangePasswordValidationSchema)
    })

    useEffect(() => {
        if (isError) {
            errorHandler({error, setError})
        }
    }, [isError])

    const onSubmit: SubmitHandler<ChangePasswordPayloadType> = data => {
        mutate(data)
    }

    return (
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
            {isPending && <Spinner />}
            <StyledPasswordTitle>{t('edit_profile:change_password')}</StyledPasswordTitle>
            <StyledInputWrapper>
                <InputText
                    type={'password'}
                    label={`${t('commons:current_password')} *`}
                    errorMessage={t(errors[CHANGE_PASSWORD_MODEL.CurrentPassword]?.message || '')}
                    visibilityToggle={true}
                    {...register(CHANGE_PASSWORD_MODEL.CurrentPassword)}
                />
                <InputText
                    type={'password'}
                    label={`${t('commons:new_password')} *`}
                    errorMessage={t(errors[CHANGE_PASSWORD_MODEL.NewPassword]?.message || '')}
                    visibilityToggle={true}
                    {...register(CHANGE_PASSWORD_MODEL.NewPassword)}
                />
                <InputText
                    type={'password'}
                    label={`${t('commons:confirm_new_password')} *`}
                    errorMessage={t(errors[CHANGE_PASSWORD_MODEL.NewPasswordConfirmation]?.message || '')}
                    visibilityToggle={true}
                    {...register(CHANGE_PASSWORD_MODEL.NewPasswordConfirmation)}
                />
            </StyledInputWrapper>

            <Button type="submit" disabled={isPending}>
                {t('commons:confirm_new_password')}
                <CheckIcon stroke={isPending ? palette.primary[700] : palette.neutral.white} />
            </Button>
        </StyledForm>
    )
}
ChangePassword.displayName = 'ChangePassword'
