import {Navigate, Outlet} from 'react-router-dom'
import {FC} from 'react'
import {useMe} from '@/features/auth/queries/useMe'
import {ROUTE_HOME} from '@utilities/constants'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'

type RoleRouteProps = {
    grantedRoles: string[]
}

const RoleBasedRoute: FC<RoleRouteProps> = ({grantedRoles}) => {
    const {data: user, isLoading} = useMe()

    if (isLoading) {
        return <Spinner />
    }

    const userRole = user?.company?.type
    const isGranted = !!userRole && grantedRoles.includes(userRole)

    return isGranted ? <Outlet /> : <Navigate to={ROUTE_HOME} />
}

export default RoleBasedRoute
