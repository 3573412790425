import {useQuery} from '@tanstack/react-query'
import {QUOTE_API} from '../services/quote.http'
import {QUOTE_QUERY_KEYS} from './keys'

export const useGetQuoteCalculations = ({quoteId}: {quoteId?: string}) => {
    return useQuery({
        queryKey: [QUOTE_QUERY_KEYS.quoteCalculations, quoteId],
        queryFn: () => QUOTE_API.getQuoteCalculations(quoteId)
    })
}
