import {z} from 'zod'

const boolEnvVar = z
    .string()
    .optional()
    .transform(value => value == 'true')

export const envVars = z
    .object({
        VITE_APP_TITLE: z.string().optional(),
        VITE_API_ENDPOINT: z.string().url(),
        VITE_GOOGLE_MAP_API_KEY: z.string().optional(), //2D
        VITE_GOOGLE_3D_TILES_API_KEY: z.string().optional(), //3D
        VITE_GOOGLE_MAP_MAP_ID: z.string().optional(),
        VITE_GOOGLE_MAP_MAP_ID_VECTOR: z.string().optional(),
        VITE_GOOGLE_SOLAR_API_KEY: z.string().optional(),
        VITE_ENABLE_3D_MAP: boolEnvVar.optional(),
        VITE_FRONTEND_URL: z.string().url()
    })
    .parse(import.meta.env)
