import {z} from 'zod'
import {ConsumptionInputTypesE, InputTimeRangeE} from '../types'

const TimeRange = z.nativeEnum(InputTimeRangeE)
const InputType = z.nativeEnum(ConsumptionInputTypesE)

export const enum ENERGY_MODEL {
    AnnualConsumption = 'annualConsumption',
    BillValue = 'billValue'
}

export const EnergyPayloadSchema = z.object({
    [ENERGY_MODEL.AnnualConsumption]: z
        .string()
        .max(15, {message: 'errors:max_length_exceeded'})
        .refine(val => parseFloat(val) > 0, {message: 'errors:must_be_greater_than_0'})
        .optional(),
    [ENERGY_MODEL.BillValue]: z
        .string()
        .max(15, {message: 'errors:max_length_exceeded'})
        .refine(val => parseFloat(val) > 0, {message: 'errors:must_be_greater_than_0'})
        .optional()
})

export const SendElectricityConsumptionPayloadSchema = z.object({
    type: InputType,
    timeRange: TimeRange,
    value: z.string().optional()
})

export const MonthlyConsumptionResponseSchema = z.object({
    month: z.number(),
    day: z.number(),
    timeRange: TimeRange,
    value: z.number()
})

export const ProjectSystemsSchema = z.object({
    deletedAt: z.string().nullable(),
    id: z.string(),
    projectId: z.string(),
    meterPower: z.string().nullable(),
    systemPower: z.string().nullable(),
    panelProductId: z.string().nullable(),
    optimizerProductId: z.string().nullable(),
    batteryProductId: z.string().nullable(),
    roofType: z.string(),
    type: z.string().optional().nullable(),
    roofSurface: z.string().nullable(),
    firstRoofMeasure: z.string().nullable(),
    secondRoofMeasure: z.string().nullable(),
    roofOrientation: z.string(),
    createdAt: z.string(),
    updatedAt: z.string(),
    name: z.string(),
    price: z.number().optional(), //TODO ask BE add this value
    status: z.string().optional(), //TODO ask BE add this value
    finalPrice: z.number().optional(),
    quote: z.object({
        id: z.string().uuid()
    })
})
