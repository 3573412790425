export const BatteryIcon = () => {
    return (
        <svg width="108" height="108" viewBox="0 0 108 108" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_2827_51229)">
                <rect x="4" width="100" height="100" rx="24" fill="url(#paint0_linear_2827_51229)" />
                <rect x="5" y="1" width="98" height="98" rx="23" stroke="#6CB691" stroke-width="2" />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M71.7406 36.3462C73.0039 36.3462 74.2136 36.8479 75.106 37.7403C75.9985 38.6327 76.5002 39.8425 76.5002 41.1058V66.4904C76.5002 67.7537 75.9985 68.9634 75.106 69.8558C74.2136 70.7482 73.0039 71.25 71.7406 71.25H36.8368C35.5735 71.25 34.3637 70.7483 33.4713 69.8558C32.5789 68.9634 32.0771 67.7537 32.0771 66.4904V41.1058C32.0771 39.8425 32.5789 38.6327 33.4713 37.7403C34.3637 36.8479 35.5735 36.3462 36.8368 36.3462V34.7596C36.8368 32.1299 38.9667 30 41.5964 30H44.7695C47.3991 30 49.5291 32.1299 49.5291 34.7596V36.3462H59.0483V34.7596C59.0483 32.1299 61.1782 30 63.8079 30H66.981C69.6107 30 71.7406 32.1299 71.7406 34.7596V36.3462ZM68.5675 36.3462V34.7596C68.5675 33.883 67.8576 33.1731 66.981 33.1731H63.8079C62.9313 33.1731 62.2214 33.883 62.2214 34.7596V36.3462H68.5675ZM46.356 36.3462V34.7596C46.356 33.883 45.646 33.1731 44.7695 33.1731H41.5964C40.7198 33.1731 40.0098 33.883 40.0098 34.7596V36.3462H46.356ZM71.7406 39.5192H36.8368C36.4163 39.5192 36.0118 39.6858 35.7143 39.9833C35.4168 40.2808 35.2502 40.6853 35.2502 41.1057V66.4904C35.2502 66.9108 35.4168 67.3154 35.7143 67.6128C36.0118 67.9103 36.4163 68.0769 36.8368 68.0769H71.7406C72.161 68.0769 72.5656 67.9103 72.8631 67.6128C73.1605 67.3153 73.3271 66.9108 73.3271 66.4904V41.1057C73.3271 40.6853 73.1605 40.2808 72.8631 39.9833C72.5656 39.6858 72.161 39.5192 71.7406 39.5192ZM56.2443 43.2635C56.8036 42.5912 57.8051 42.498 58.4774 43.0612C59.1496 43.6205 59.2428 44.6219 58.6796 45.2942L52.9163 52.2115H59.0482C59.663 52.2115 60.2242 52.5665 60.486 53.1258C60.7458 53.6831 60.6625 54.3414 60.2659 54.8134L52.3332 64.3327C51.7739 65.005 50.7724 65.0982 50.1001 64.535C49.4279 63.9757 49.3347 62.9742 49.8979 62.3019L55.6612 55.3846H49.5293C48.9145 55.3846 48.3533 55.0296 48.0915 54.4704C47.8317 53.9131 47.915 53.2547 48.3116 52.7827L56.2443 43.2635Z"
                    fill="white"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_2827_51229"
                    x="0"
                    y="0"
                    width="108"
                    height="108"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2827_51229" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2827_51229" result="shape" />
                </filter>
                <linearGradient
                    id="paint0_linear_2827_51229"
                    x1="3.99844"
                    y1="99.9994"
                    x2="103.999"
                    y2="-0.00121755"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#1E2322" />
                    <stop offset="1" stop-color="#5F937D" />
                </linearGradient>
            </defs>
        </svg>
    )
}
