import {useLocation} from 'react-router-dom'
import QuotePDF from './QuotePdf'
import {Component as Quote} from '@pages/quote/Quote.tsx'

const QuoteWrapper = () => {
    const location = useLocation()
    const isPDF = new URLSearchParams(location.search).get('pdf') === 'true'

    return isPDF ? <QuotePDF /> : <Quote />
}

export default QuoteWrapper
