import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledSectionHeader = styled(Flexbox)(
    ({theme: {palette}}) => css`
        width: 100%;
        color: ${palette.primary[900]};
    `
)

export const StyledTitle = styled.h3(
    ({theme: {typography}}) => css`
        ${typography.displaySm};
        font-weight: 500;
    `
)

export const StyledItem = styled(Flexbox)(
    ({theme: {palette, typography}}) => css`
        font-weight: 500;

        & span {
            color: ${palette.primary[950]};
            ${typography.displaySm};
        }

        & p {
            ${typography.textXs}
            color: ${palette.primary[700]}
        }
    `
)

export const StyledResult = styled(Flexbox)(
    ({theme: {typography}}) => css`
        font-weight: 700;

        & span {
            background: linear-gradient(90deg, #5f937d, #6cb691);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            color: transparent;
            ${typography.displaySm};
        }

        & p {
            ${typography.textXs}
            background: linear-gradient(90deg, #5f937d, #6cb691);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            color: transparent;
        }
    `
)

export const StyledSign = styled.span(
    ({theme: {typography, palette}}) => css`
        color: ${palette.primary[950]};
        ${typography.displaySm};
        font-weight: 500;
    `
)
