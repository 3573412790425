import {useTranslation} from 'react-i18next'

import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {StyledEnergy, StyledPercentage, StyledSectionHeader, StyledTitle} from './style'
import {QuoteCalculationsType} from '@/features/quote/types.ts'

export const PerformanceHeader = ({quoteCalculationsData}: {quoteCalculationsData: QuoteCalculationsType}) => {
    const {t} = useTranslation()

    const energyPercentage = quoteCalculationsData.annualOffset
    return (
        <StyledSectionHeader justify="space-between" align="center">
            <StyledTitle>{t('quote:performance:title')}</StyledTitle>
            <Flexbox gap={1} direction="column">
                <StyledEnergy>{t('quote:performance:energy')}</StyledEnergy>
                <StyledPercentage>{energyPercentage}%</StyledPercentage>
            </Flexbox>
        </StyledSectionHeader>
    )
}
