export const ElectricityGridIcon = () => {
    return (
        <svg width="108" height="108" viewBox="0 0 108 108" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_2827_51031)">
                <rect x="4" width="100" height="100" rx="24" fill="url(#paint0_linear_2827_51031)" />
                <rect x="5" y="1" width="98" height="98" rx="23" stroke="#6CB691" stroke-width="2" />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M54 27C53.655 27 53.3308 27.1621 53.1227 27.4381L46.5513 36.2C46.4078 36.3928 46.3366 36.6217 46.3355 36.8528C46.3344 36.8539 46.3344 36.856 46.3333 36.8571V39.523L35.9132 45.7757C35.5792 45.975 35.3875 46.3331 35.3853 46.7077C35.3766 48.1666 35.381 49.6331 35.381 51.0952C35.381 51.6998 35.8716 52.1905 36.4762 52.1905C37.0808 52.1905 37.5714 51.6998 37.5714 51.0952V47.8095H46.3333V58.5034L40.1814 70.8095H32.0952C31.4907 70.8095 31 71.3002 31 71.9048C31 72.5093 31.4907 73 32.0952 73H75.9048C76.5093 73 77 72.5093 77 71.9048C77 71.3002 76.5093 70.8095 75.9048 70.8095H67.8186L61.6667 58.5034V47.8095H70.4286V51.0952C70.4286 51.6998 70.9192 52.1905 71.5238 52.1905C72.1284 52.1905 72.619 51.6998 72.619 51.0952V46.7143C72.6136 46.2499 72.2576 46.0243 72.0868 45.7757L61.6667 39.523V36.8571C61.6656 36.855 61.6656 36.8528 61.6645 36.8506C61.6634 36.6206 61.5911 36.3917 61.4487 36.2L54.8773 27.4381C54.6692 27.1621 54.345 27 54 27ZM54 29.9199L58.381 35.7619H49.619L54 29.9199ZM48.5238 37.9524H59.4762V39.5569L54 43.2084L48.5238 39.5569V37.9524ZM46.3333 42.077V45.619H40.4289L46.3333 42.077ZM61.6667 42.077L67.5711 45.619H61.6667V42.077ZM48.5238 42.1899L52.0253 44.5238L48.5238 46.8578V42.1899ZM59.4762 42.1899V46.8578L55.9747 44.5238L59.4762 42.1899ZM54 45.8392L58.5967 48.9048L54 51.9703L49.4033 48.9048L54 45.8392ZM48.5238 50.9518L52.0253 53.2857L48.5238 55.6197V50.9518ZM59.4762 50.9518V55.6197L55.9747 53.2857L59.4762 50.9518ZM54 54.6011L59.4181 58.2143L54 61.8275L48.5819 58.2143L54 54.6011ZM47.8546 60.362L52.0253 63.1429L43.6807 68.7045L47.8546 60.362ZM60.1454 60.362L64.3193 68.7045L55.9747 63.1429L60.1454 60.362ZM54 64.4582L63.5253 70.8095H44.4747L54 64.4582Z"
                    fill="white"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_2827_51031"
                    x="0"
                    y="0"
                    width="108"
                    height="108"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2827_51031" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2827_51031" result="shape" />
                </filter>
                <linearGradient
                    id="paint0_linear_2827_51031"
                    x1="3.99844"
                    y1="99.9994"
                    x2="103.999"
                    y2="-0.00121755"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#1E2322" />
                    <stop offset="1" stop-color="#5F937D" />
                </linearGradient>
            </defs>
        </svg>
    )
}
