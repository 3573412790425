import {PROFILE_USER_MODEL} from '@/features/profile/services/profile.schema'
import {UserRoleE} from '@/utilities/constants'
import {EMAIL_MAX_CHARS} from '@/utilities/constants/input'
import {NUMBERS_ONLY_REGEX, PHONE_REGEX} from '@/utilities/constants/regex'
import {z} from 'zod'

export const enum LOGIN_MODEL {
    Email = 'email',
    Password = 'password'
}

export const LoginFormSchema = z.object({
    [LOGIN_MODEL.Email]: z
        .string()
        .min(1, {message: 'errors:email'})
        .email({message: 'errors:email_valid'})
        .max(EMAIL_MAX_CHARS, {message: 'errors:email_too_long'}),
    [LOGIN_MODEL.Password]: z.string().min(1, {message: 'errors:required_password'})
})

export const RoleSchema = z.object({
    id: z.string(),
    slug: z.string(),
    name: z.string(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime()
})

export const CompanySchema = z.object({
    id: z.string(),
    type: z.enum([UserRoleE.SUPPLIER, UserRoleE.RESELLER, UserRoleE.INSTALLER]),
    parentCompanyId: z.string().nullable(),
    userId: z.string(),
    name: z.string(),
    address: z.string(),
    logoImagePath: z.string().nullable(),
    vatNumber: z.string(),
    phoneNumber: z.string(),
    createdAt: z.string(),
    updatedAt: z.string(),
    deletedAt: z.string().nullable().optional(),
    email: z.string().nullable()
})

export const UserSchema = z.object({
    id: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    email: z.string().email(),
    phoneNumber: z.string().nullable(),
    qualification: z.string().nullable(),
    vatNumber: z.coerce.number().int().nullable(),
    companyName: z.string().nullable(),
    companyId: z.string(),
    createdAt: z.string(),
    updatedAt: z.string(),
    role: RoleSchema,
    company: CompanySchema,
    logoImageUrl: z.string().nullable()
})

export const AuthStateSchema = z.object({
    user: UserSchema.nullable(),
    accessToken: z.string(),
    refreshToken: z.string(),
    adminConfig: z.object({
        isActive: z.boolean()
    })
})

export const CreateUserProfileValidationSchema = z.object({
    [PROFILE_USER_MODEL.FirstName]: z.string().min(1, {message: 'errors:name_required'}),
    [PROFILE_USER_MODEL.LastName]: z.string().min(1, {message: 'errors:surname_required'}),
    [PROFILE_USER_MODEL.PhoneNumber]: z
        .string()
        .min(1, {message: 'errors:phone_required'})
        .min(10, {message: 'errors:phone_too_short'})
        .max(15, {message: 'errors:phone_too_long'})
        .regex(PHONE_REGEX, {message: 'errors:invalid_phone_number'}),
    [PROFILE_USER_MODEL.Email]: z.string().min(1, {message: 'errors:email'}).email({message: 'errors:email_valid'}),
    [PROFILE_USER_MODEL.CompanyName]: z.string().min(1, {message: 'errors:agency_required'}),
    [PROFILE_USER_MODEL.CompanyVatNumber]: z.string().regex(NUMBERS_ONLY_REGEX, {message: 'errors:numbers_only'}),
    [PROFILE_USER_MODEL.LogoImage]: z.string().optional(),
    [PROFILE_USER_MODEL.CompanyAddress]: z.string().min(1, {message: 'errors:field_required'}).optional(),
    [PROFILE_USER_MODEL.Address]: z.string().optional(),
    [PROFILE_USER_MODEL.VatNumber]: z.string().optional()
})

export const CreateInstallerProfilePayloadSchema = CreateUserProfileValidationSchema.extend({
    [PROFILE_USER_MODEL.Token]: z.string(),
    [PROFILE_USER_MODEL.Address]: z.string(),
    [PROFILE_USER_MODEL.VatNumber]: z.string()
})

export const CreateResellerProfilePayloadSchema = CreateUserProfileValidationSchema.extend({
    [PROFILE_USER_MODEL.Token]: z.string()
})
