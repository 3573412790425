export const palette = {
    light: {
        neutral: {
            white: '#FFFFFF',
            25: '#FAFAFA',
            50: '#F5F5F6',
            100: '#F0F1F1',
            200: '#ECECED',
            300: '#CECFD2',
            400: '#94969C',
            500: '#85888E',
            600: '#61646C',
            700: '#333741',
            800: '#1F242F',
            900: '#161B26',
            950: '#0C111D',
            black: '#000000'
        },
        primary: {
            25: '#FEFFFE',
            50: '#F9FEFB',
            100: '#E4F9EF',
            200: '#C6F3DD',
            300: '#A9EDCB',
            400: '#8FD4B2',
            500: '#6CB691',
            600: '#5F937D',
            700: '#4F645B',
            800: '#3B4B43',
            900: '#1E2322',
            950: '#141716'
        },
        secondary: {
            25: '#F2F6FD',
            50: '#B2B1B7',
            100: '#8A909E',
            200: '#6A707E',
            300: '#464A53',
            400: '#8A909E',
            500: '#8FD4B2',
            600: '#464A53',
            700: '#8A909E',
            800: '#6A707E',
            900: '#464A53',
            950: '#102A56'
        },
        success: {
            25: '#F6FEF9',
            50: '#ECFDF3',
            100: '#DCFAE6',
            200: '#A9EFC5',
            300: '#75E0A7',
            400: '#47CD89',
            500: '#17B26A',
            600: '#079455',
            700: '#067647',
            800: '#085D3A',
            900: '#074D31',
            950: '#053321'
        },
        danger: {
            25: '#FFF5F6',
            50: '#FFF1F3',
            100: '#FFE4E8',
            200: '#FECDD6',
            300: '#FEA3B4',
            400: '#FD6F8E',
            500: '#F63D68',
            600: '#E31B54',
            700: '#C01048',
            800: '#A11043',
            900: '#89123E',
            950: '#510B24'
        },
        warning: {
            25: '#FFFCF5',
            50: '#FFFAEB',
            100: '#FEF0C7',
            200: '#FEDF89',
            300: '#FEC84B',
            400: '#FDB022',
            500: '#F79009',
            600: '#DC6803',
            700: '#B54708',
            800: '#93370D',
            900: '#7A2E0E',
            950: '#4E1D09'
        }
    }
    //we will support the dark mode documenting this one and adding the dark types to our style.d.ts
    /*dark: null*/
}
