import {LocalStorageManager} from '@/utilities/localStorage'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {AUTH_API} from '../services/auth.http'
import {useAuthStore} from '../store/useAuthStore'

export const useLogout = (onSuccess?: () => void) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: AUTH_API.logout,
        onSuccess: () => {
            queryClient.clear()
            LocalStorageManager.accessToken.remove()
            LocalStorageManager.refreshToken.remove()
            useAuthStore.setState(() => ({
                accessToken: '',
                refreshToken: '',
                user: null
            }))
            onSuccess?.()
        }
    })
}
