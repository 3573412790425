import {HomeLineIcon, ListIcon, PeopleIcon, PercentIcon, ProjectIcon, ToolsIcon} from '@/assets/icons/icons'
import {
    ROUTE_DISCOUNTS,
    ROUTE_HOME,
    ROUTE_INSTALLER_DISCOUNTS,
    ROUTE_INSTALLER_PRODUCTS,
    ROUTE_INSTALLERS,
    ROUTE_PRODUCTS,
    ROUTE_PROJECTS,
    ROUTE_RESELLERS,
    UserRoleE
} from '@/utilities/constants'

export const getCorrectMenuItemsList = (userRole?: UserRoleE) => {
    const topMenuItems = [
        {
            id: 'home',
            icon: <HomeLineIcon />,
            to: ROUTE_HOME,
            title: 'route_names:home',
            show: true
        },
        {
            id: 'projects',
            icon: <ProjectIcon />,
            to: ROUTE_PROJECTS,
            title: 'route_names:projects',
            show: true
        },
        {
            id: 'resellers',
            icon: <PeopleIcon />,
            to: ROUTE_RESELLERS,
            title: 'route_names:resellers',
            show: userRole === UserRoleE.SUPPLIER
        },
        {
            id: 'installers',
            icon: <ToolsIcon />,
            to: ROUTE_INSTALLERS,
            title: 'route_names:installers',
            show: userRole === UserRoleE.RESELLER
        }
    ]

    const bottomMenuItems = [
        {
            id: 'list',
            icon: <ListIcon />,
            title: 'route_names:list',
            to: ROUTE_PRODUCTS,
            show: true
        },
        {
            id: 'discounts',
            icon: <PercentIcon />,
            title: 'route_names:discounts',
            to: ROUTE_DISCOUNTS,
            show: true
        }
    ]

    const bottomMenuItemsReseller = [
        {
            id: 'list',
            icon: <ListIcon />,
            title: 'route_names:list',
            to: ROUTE_PRODUCTS,
            show: true
        },
        {
            id: 'discounts',
            icon: <PercentIcon />,
            title: 'route_names:refill',
            to: ROUTE_DISCOUNTS,
            show: true
        }
    ]

    const bottomMenuItemsInstaller = [
        {
            id: 'list',
            icon: <ListIcon />,
            title: 'route_names:list',
            to: ROUTE_INSTALLER_PRODUCTS,
            show: true
        },
        {
            id: 'discounts',
            icon: <PercentIcon />,
            title: 'route_names:refill',
            to: ROUTE_INSTALLER_DISCOUNTS,
            show: true
        }
    ]
    return {topMenuItems, bottomMenuItems, bottomMenuItemsReseller, bottomMenuItemsInstaller}
}
