export const HomeIconPdf = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="67" height="67" viewBox="0 0 67 67" fill="none">
            <g filter="url(#filter0_d_4506_58736)">
                <rect
                    x="2.54297"
                    y="0.508057"
                    width="61.1336"
                    height="61.1336"
                    rx="14.6721"
                    fill="url(#paint0_linear_4506_58736)"
                />
                <rect
                    x="3.1543"
                    y="1.11939"
                    width="59.9109"
                    height="59.9109"
                    rx="14.0607"
                    stroke="#6CB691"
                    stroke-width="1.22267"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M20.0555 29.5068L31.0247 19.6506C32.2166 18.5806 34.0014 18.5806 35.1934 19.6506L46.1626 29.5068C47.0791 30.3306 46.4826 31.8483 45.2649 31.8483H43.2668V40.7093C43.2668 42.4285 41.8663 43.828 40.1481 43.828L26.0707 43.8291C24.3515 43.8291 22.952 42.4298 22.952 40.7105V31.8483H20.9539C19.7327 31.8483 19.1409 30.3283 20.055 29.5067L20.0555 29.5068ZM32.2284 20.9869L22.1427 30.0483H23.8526C24.3495 30.0483 24.7526 30.4515 24.7526 30.9484V40.7094C24.7526 41.4337 25.3468 42.0278 26.071 42.0278H40.1484C40.8739 42.0278 41.4668 41.4336 41.4668 40.7094V30.9484C41.4668 30.4515 41.87 30.0483 42.3669 30.0483H44.0767L33.9911 20.9869C33.4883 20.5357 32.7311 20.5357 32.2284 20.9869Z"
                    fill="white"
                />
                <path
                    d="M38.1953 30.6993H33.4604L33.5278 26.3856C33.5278 26.2002 33.3761 26.0486 33.1908 26.0486C33.0897 26.0486 32.9717 26.0991 32.9212 26.1834L27.7819 33.1594C27.6639 33.311 27.6976 33.5132 27.8493 33.6312C27.8998 33.6817 27.9841 33.6986 28.0515 33.6986H32.7864L32.719 38.0123C32.719 38.1976 32.8706 38.3493 33.056 38.3493C33.1571 38.3493 33.275 38.2987 33.3256 38.2145L38.4818 31.2385C38.5997 31.0868 38.566 30.8846 38.4144 30.7667C38.3301 30.7161 38.2627 30.6993 38.1953 30.6993Z"
                    fill="white"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_4506_58736"
                    x="0.0976248"
                    y="0.508057"
                    width="66.0245"
                    height="66.0242"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2.44534" />
                    <feGaussianBlur stdDeviation="1.22267" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4506_58736" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4506_58736" result="shape" />
                </filter>
                <linearGradient
                    id="paint0_linear_4506_58736"
                    x1="2.54202"
                    y1="61.6413"
                    x2="63.6758"
                    y2="0.507312"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#1E2322" />
                    <stop offset="1" stop-color="#5F937D" />
                </linearGradient>
            </defs>
        </svg>
    )
}
