import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledComponentsQuoteWrapper = styled(Flexbox)(
    ({theme: {spacing, typography, palette}}) => css`
        padding: 0 ${spacing * 14}px;
        color: ${palette.primary[950]};
        height: 100%;
        width: 100%;

        .description-story {
            font-weight: 600;
            ${typography.textXs};
            color: ${palette.primary[700]};
        }
    `
)

export const StyledTitle = styled.div(
    ({theme: {typography}}) => css`
        ${typography.displaySm};
        font-weight: 500;
    `
)

export const StyledComponentCardWrapper = styled.div(
    () => css`
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
        gap: 20px;
        width: 100%;
    `
)

export const StyledComponentCard = styled(Flexbox)(
    ({theme: {spacing, typography, palette}}) => css`
        padding: ${spacing * 6}px;
        border-radius: 32px;
        border: 1px solid #0000001a;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        min-height: 234px;

        .image {
            height: 56px;
            width: 56px;
        }

        .title {
            font-weight: 600;
            ${typography.displayXs};
            color: ${palette.primary[950]};
        }

        .description {
            font-weight: 600;
            ${typography.textXs};
            color: ${palette.primary[950]};
        }
    `
)

export const StyledImage = styled.div`
    & svg {
        height: 56px;
        width: 56px;
    }
`
