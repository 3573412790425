import {useTranslation} from 'react-i18next'
import {
    Label,
    LabelContainer,
    StyleInfoContainer,
    StyledAcceptQuoteContainer,
    Underline,
    StyledCheckBoxToAcceptQuote
} from './style'
import {Checkbox} from '@components/commons/checkbox/Checkbox.tsx'

export const AcceptQuote = () => {
    const {t} = useTranslation()

    return (
        <StyledAcceptQuoteContainer>
            <div>
                <h4>{t('quote:operation_system:accept_quote')}</h4>
                <StyledCheckBoxToAcceptQuote>
                    <Checkbox
                        containerClass={'accept-quote-checkbox'}
                        id={'operation-system'}
                        defaultChecked={true}
                        label={t('quote:summary:terms_and_conditions')}
                    />
                </StyledCheckBoxToAcceptQuote>
            </div>
            <StyleInfoContainer>
                <LabelContainer style={{gridArea: 'name'}}>
                    <Label>{t('quote:operation_system:client_name')}</Label>
                    <Underline />
                </LabelContainer>
                <LabelContainer style={{gridArea: 'signature'}}>
                    <Label>{t('quote:operation_system:signature')}</Label>
                    <Underline />
                </LabelContainer>
                <LabelContainer style={{gridArea: 'date'}}>
                    <Label>{t('quote:operation_system:date')}</Label>
                    <Underline />
                </LabelContainer>
            </StyleInfoContainer>
        </StyledAcceptQuoteContainer>
    )
}

AcceptQuote.displayName = 'AcceptQuote'
