import {useTranslation} from 'react-i18next'
import useWindowDimensions from '@hooks/useWindowDimensions.ts'
import {useState} from 'react'
import {ROUTE_HOME, UserRoleE} from '@/utilities/constants'
import {StyledList, StyledMainWrapper, StyledRoot, StyledUserInfo} from './style'
import LogoIcon from '@assets/images/logo_icon.svg'
import {getCorrectMenuItemsList} from './menuItemsList'
import {ButtonNavLink} from '@/components/ui/button-nav-link/ButtonNavLink'
import {useNavigate} from 'react-router-dom'
import {LogoutModal} from '@/features/auth/components/logout-modal/LogoutModal'
import {EditProfileModal} from '@/features/profile/components/edit-profile-modal/EditProfileModal'
import {LogOut01Icon} from '@/assets/icons/icons'
import {useMe} from '@/features/auth/queries/useMe'

export const NavBar = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {height} = useWindowDimensions()
    const {data: user} = useMe()
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false)
    const [isEditProfileModalOpen, setIsEditProfileModalOpen] = useState(false)
    const menuList = getCorrectMenuItemsList(user?.company?.type)

    const menuBottomList = () => {
        switch (user?.company?.type) {
            case UserRoleE.SUPPLIER:
                return menuList.bottomMenuItems
            case UserRoleE.INSTALLER:
                return menuList.bottomMenuItemsInstaller
            case UserRoleE.RESELLER:
                return menuList.bottomMenuItemsReseller
            default:
                return []
        }
    }

    return (
        <StyledRoot $vh={height}>
            <StyledMainWrapper>
                <div>
                    <img src={LogoIcon} alt={'arya-icon'} onClick={() => navigate(ROUTE_HOME)} />

                    <StyledList>
                        {menuList.topMenuItems.map(item => {
                            return (
                                item.show && (
                                    <ButtonNavLink to={item.to} key={item.id}>
                                        {item.icon}
                                        <p>{t(item.title)}</p>
                                    </ButtonNavLink>
                                )
                            )
                        })}
                    </StyledList>
                </div>

                <div>
                    <StyledList>
                        {menuBottomList().map(item => {
                            return (
                                <ButtonNavLink to={item.to} key={item.id}>
                                    {item.icon}
                                    <p>{t(item.title)}</p>
                                </ButtonNavLink>
                            )
                        })}
                    </StyledList>
                    <StyledUserInfo>
                        <div onClick={() => setIsEditProfileModalOpen(true)}>
                            <p>{`${user?.firstName} ${user?.lastName}`}</p>
                            <span>{user?.email}</span>
                        </div>
                        <LogOut01Icon onClick={() => setIsLogoutModalOpen(true)} />
                    </StyledUserInfo>
                </div>
            </StyledMainWrapper>
            {isLogoutModalOpen && <LogoutModal onClose={() => setIsLogoutModalOpen(false)} />}
            {isEditProfileModalOpen && <EditProfileModal onClose={() => setIsEditProfileModalOpen(false)} />}
        </StyledRoot>
    )
}
