import {create} from 'zustand'
import {AuthStateType, UserType} from '../types'
import {LOCAL_STORAGE_ACCESS_TOKEN, LOCAL_STORAGE_REFRESH_TOKEN} from '@/utilities/constants'

type AuthStoreActions = {
    reset: () => void
    setAuthData: (data: {user: UserType; accessToken: string; refreshToken: string}) => void
}

export const useAuthStore = create<Partial<AuthStateType> & AuthStoreActions>()(set => ({
    user: null,
    accessToken: localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN) || undefined,
    refreshToken: localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN) || undefined,
    reset: () => {
        localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN)
        localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN)
        set({user: null, accessToken: undefined, refreshToken: undefined})
    },
    setAuthData: data => {
        localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN, data.accessToken)
        localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, data.refreshToken)
        set({user: data.user, accessToken: data.accessToken, refreshToken: data.refreshToken})
    }
}))
