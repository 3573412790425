import {Outlet} from 'react-router-dom'
import React from 'react'
import {StyledPublicLayout} from './style'
import {Container} from '@/components/ui/container/Container'

export const PublicLayout: React.FC = () => {
    return (
        <StyledPublicLayout>
            <Container fullHeight fullWidth>
                <Outlet />
            </Container>
        </StyledPublicLayout>
    )
}
