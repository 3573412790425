import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledSavingQuotesWrapper = styled(Flexbox)(
    ({theme: {spacing, typography, palette}}) => css`
        padding: ${spacing * 14}px;
        width: 100%;
        margin-top: ${spacing * 28}px;

        .title {
            ${typography.displaySm};
            font-weight: 500;
            color: ${palette.primary[950]};
        }

        .table {
            ${typography.textSm};
            font-weight: 600;
            text-decoration: underline;
            color: ${palette.primary[800]};
            cursor: pointer;
        }
    `
)

export const StyledChartsWrapper = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: ${spacing * 6}px;
        width: 100%;
    `
)

export const StyledChartsSubtitle = styled.p(
    ({theme: {typography, palette}}) => css`
        ${typography.textMd};
        font-weight: 500;
        color: ${palette.primary[700]};
    `
)
