import {Button} from '@/components/ui/button/Button'
import {FC} from 'react'
import {StyledNotFoundContainer, StyledRoot} from './style'
import {useNavigate} from 'react-router-dom'
import {Trans, useTranslation} from 'react-i18next'
import {ROUTE_HOME} from '@/utilities/constants/routeNames'
import {ArrowRightIcon, NotFoundIcon} from '@/assets/icons/icons'

export const NotFound: FC = () => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    return (
        <StyledRoot>
            <StyledNotFoundContainer justify="center" direction="column" align="center" gap={6}>
                <NotFoundIcon />
                <h3>{t('not_found:title')}</h3>

                <p>
                    <Trans t={t} i18nKey="not_found:message" />
                </p>

                <Button onClick={() => navigate(ROUTE_HOME)} fullWidth>
                    {t('not_found:cta')} <ArrowRightIcon />
                </Button>
            </StyledNotFoundContainer>
        </StyledRoot>
    )
}
NotFound.displayName = 'NotFound'
