import styled, {css} from 'styled-components'

export const StyledForm = styled.form(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 6}px;
        & button {
            align-self: end;
        }
    `
)

export const StyledPasswordTitle = styled.p`
    ${({theme: {typography, palette}}) => css`
        ${typography.displayXs}
        color: ${palette.primary[25]};
    `}
`

export const StyledInputWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 2.5}px;
    `}
`
