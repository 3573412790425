import {Outlet} from 'react-router-dom'
import {StyledPrivateLayout} from './style.ts'
import React from 'react'

export const PrivateLayout: React.FC = () => {
    return (
        <StyledPrivateLayout>
            <Outlet />
        </StyledPrivateLayout>
    )
}
