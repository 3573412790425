import {
    LOCAL_STORAGE_ACCESS_TOKEN,
    LOCAL_STORAGE_REFRESH_TOKEN,
    LOCAL_STORAGE_SELECTED_RESELLER_ID,
    LOCAL_STORAGE_USER_INVITATION_TOKEN
} from './constants'

const accessToken = {
    set: (token: string | null) => {
        if (token !== null) {
            localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN, token)
        } else {
            localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN)
        }
    },
    get: () => {
        return localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN)
    },
    remove: () => {
        localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN)
    }
}

const refreshToken = {
    set: (token: string | null) => {
        if (token !== null) {
            localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, token)
        } else {
            localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN)
        }
    },
    get: () => {
        return localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN)
    },
    remove: () => {
        localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN)
    }
}

const selectedReseller = {
    set: (selectedResellerId: string | undefined) => {
        if (selectedResellerId) {
            localStorage.setItem(LOCAL_STORAGE_SELECTED_RESELLER_ID, JSON.stringify(selectedResellerId))
        }
    },
    get: () => {
        const selectedResellerId = localStorage.getItem(LOCAL_STORAGE_SELECTED_RESELLER_ID)
        return selectedResellerId ? JSON.parse(selectedResellerId) : null
    },
    remove: () => {
        localStorage.removeItem(LOCAL_STORAGE_SELECTED_RESELLER_ID)
    }
}

const invitationUserToken = {
    set: (token: string | null) => {
        localStorage.setItem(LOCAL_STORAGE_USER_INVITATION_TOKEN, JSON.stringify(token))
    },
    get: () => {
        const invitation = localStorage.getItem(LOCAL_STORAGE_USER_INVITATION_TOKEN)
        return invitation ? JSON.parse(invitation) : null
    },
    remove: () => {
        localStorage.removeItem(LOCAL_STORAGE_USER_INVITATION_TOKEN)
    }
}

export const LocalStorageManager = {
    accessToken,
    refreshToken,
    selectedReseller,
    invitationUserToken
}
