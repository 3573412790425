import styled, {css} from 'styled-components'
import {NavLink} from 'react-router-dom'

export const StyledButtonNavLink = styled(NavLink)(
    ({theme: {typography, palette, spacing}}) => css`
        background: inherit;
        color: ${palette.primary[200]};
        display: flex;
        gap: ${spacing * 3}px;
        font-weight: 600;
        align-items: center;
        padding: ${spacing * 2}px ${spacing * 3}px;
        ${typography.textMd}
        cursor: pointer;

        & svg {
            fill: ${palette.primary[950]};
        }

        &.active {
            background: ${palette.primary[800]};
            border-radius: 6px;

            & svg {
                fill: ${palette.primary[800]};
                & path {
                    stroke: ${palette.neutral[200]};
                }
            }
        }
    `
)
