export const HomeIcon = () => {
    return (
        <svg width="108" height="108" viewBox="0 0 108 108" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_2827_51023)">
                <rect x="4" width="100" height="100" rx="24" fill="url(#paint0_linear_2827_51023)" />
                <rect x="5" y="1" width="98" height="98" rx="23" stroke="#6CB691" stroke-width="2" />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M32.6471 47.435L50.5901 31.3127C52.5397 29.5624 55.4593 29.5624 57.4092 31.3127L75.3522 47.435C76.8513 48.7826 75.8755 51.2652 73.8837 51.2652H70.6152V65.7596C70.6152 68.5719 68.3243 70.861 65.5138 70.861L42.4866 70.863C39.6743 70.863 37.3851 68.574 37.3851 65.7616V51.2651H34.1166C32.1191 51.2651 31.151 48.7788 32.6463 47.4349L32.6471 47.435ZM52.559 33.4985L36.0613 48.3208H38.8582C39.671 48.3208 40.3305 48.9803 40.3305 49.7931V65.7598C40.3305 66.9445 41.3024 67.9164 42.4871 67.9164H65.5143C66.701 67.9164 67.6709 66.9445 67.6709 65.7598V49.7931C67.6709 48.9803 68.3304 48.3208 69.1432 48.3208H71.9401L55.4424 33.4985C54.62 32.7605 53.3814 32.7605 52.559 33.4985Z"
                    fill="white"
                />
                <path
                    d="M62.3189 49.3855H54.5737L54.6839 42.3293C54.6839 42.0261 54.4359 41.7781 54.1327 41.7781C53.9673 41.7781 53.7744 41.8608 53.6917 41.9986L45.285 53.4097C45.092 53.6577 45.1472 53.9885 45.3952 54.1814C45.4779 54.2641 45.6157 54.2917 45.726 54.2917H53.4712L53.3609 61.3478C53.3609 61.651 53.609 61.8991 53.9122 61.8991C54.0776 61.8991 54.2705 61.8164 54.3532 61.6785L62.7875 50.2675C62.9804 50.0194 62.9253 49.6887 62.6772 49.4957C62.5394 49.413 62.4291 49.3855 62.3189 49.3855Z"
                    fill="white"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_2827_51023"
                    x="0"
                    y="0"
                    width="108"
                    height="108"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2827_51023" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2827_51023" result="shape" />
                </filter>
                <linearGradient
                    id="paint0_linear_2827_51023"
                    x1="3.99844"
                    y1="99.9994"
                    x2="103.999"
                    y2="-0.00121755"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#1E2322" />
                    <stop offset="1" stop-color="#5F937D" />
                </linearGradient>
            </defs>
        </svg>
    )
}
