import {FC} from 'react'
import {BarProps, Rectangle} from 'recharts'

type Payload = {
    uv?: number
    pv?: number
}

type CustomBarProps = BarProps & {
    payload?: Payload
}

export const CustomBar: FC<CustomBarProps> = props => {
    const {x, y, height, payload, dataKey} = props

    const validX = x !== undefined ? Number(x) : 0
    const validY = y !== undefined ? Number(y) : 0
    const validHeight = height !== undefined ? Number(height) : 0

    const value = payload ? payload[dataKey as keyof Payload] : null

    const barWidth = 32
    const radius = 10

    const barFill = value && value < 0 ? '#E4E7EC' : 'url(#gradient)'

    return (
        <>
            <Rectangle
                x={validX}
                y={validY}
                width={barWidth}
                height={validHeight}
                fill={barFill}
                radius={[radius, radius, 0, 0]}
            />
        </>
    )
}
