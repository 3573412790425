import React from 'react'
import * as TabsPrimitive from '@radix-ui/react-tabs'
import styled from 'styled-components'

interface Tab {
    title: string
    content: React.ReactNode
}

interface TabsProps {
    tabs: Tab[]
    defaultValue?: string
}

export const Tabs: React.FC<TabsProps> = ({tabs, defaultValue}) => {
    return (
        <TabsRoot defaultValue={defaultValue || tabs[0].title}>
            <TabList>
                {tabs.map(tab => (
                    <TabTrigger key={tab.title} value={tab.title}>
                        {tab.title}
                    </TabTrigger>
                ))}
            </TabList>

            <TabContentWrapper>
                {tabs.map(tab => (
                    <TabContent key={tab.title} value={tab.title}>
                        {tab.content}
                    </TabContent>
                ))}
            </TabContentWrapper>
        </TabsRoot>
    )
}

export default Tabs

// Styled components

const TabsRoot = styled(TabsPrimitive.Root)`
    width: 100%;
`

const TabList = styled(TabsPrimitive.List)`
    display: flex;
    justify-content: flex-start;
    padding: 2px;
    gap: 10px;
    background-color: #e6f4e6;
    border-radius: 8px;
    width: fit-content;
`

const TabTrigger = styled(TabsPrimitive.Trigger)`
    padding: 10px 20px;
    font-weight: bold;
    color: #5d896a;
    background-color: transparent;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;

    &[data-state='active'] {
        background-color: white;
        color: #4f645b;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
`

const TabContent = styled(TabsPrimitive.Content)`
    margin-top: 20px;
    color: #333;

    &[data-state='inactive'] {
        display: none;
    }
`

const TabContentWrapper = styled.div`
    border: 1px solid #0000001a;
    border-radius: 32px;
    margin-top: 20px;
    padding: 45px;
`
