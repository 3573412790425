import {Button} from '@/components/ui/button/Button'
import styled, {css} from 'styled-components'

export const StyledForm = styled.form(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 6}px;
    `
)

export const StyledDescription = styled.p(
    ({theme: {typography, palette}}) => css`
        ${typography.displayXs}
        color: ${palette.primary[25]};
    `
)

export const StyledInputContainer = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: ${spacing * 6}px ${spacing * 2.5}px;
    `}
`
export const StyledInputWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 2.5}px;
    `}
`
export const StyledButtonWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        justify-content: space-between;
        gap: ${spacing * 2.5}px;
    `}
`

export const StyledButton = styled(Button)`
    ${({theme: {palette}}) => css`
        color: ${palette.primary[600]};
        border-color: ${palette.primary[800]};
    `}
`
export const StyledPasswordContainer = styled.div`
    ${({theme: {spacing, typography, palette}}) => css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: ${spacing * 6}px;
        ${typography.textSm}
        align-items: center;
        & h3 {
            font-weight: 600;
            color: ${palette.primary[300]};
        }
    `}
`
export const StyledPasswordButton = styled(Button)`
    ${({theme: {palette, spacing}}) => css`
        color: ${palette.primary[300]};
        border-color: ${palette.primary[400]};
        width: fit-content;
        padding: ${spacing * 2}px ${spacing * 3}px;
        margin-left: auto;
        background-color: ${palette.primary[950]};
    `}
`
