import {createGlobalStyle, withTheme} from 'styled-components'
import {palette} from '@/theme/palette.ts'
import {defaultFontFamily} from './typography'

const locationIsQuoted = window.location.pathname.includes('quote')
const ResetStyles = createGlobalStyle`
* {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  }

  html{
    height: 100%;
    font-size: 14px;
    font-family: ${defaultFontFamily};
    color: ${palette.light.neutral.white};
    background-color: ${locationIsQuoted ? palette.light.neutral['white'] : palette.light.primary[950]};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: none;
  }

  body {
    height: 100%;
  }

  #root {
    height: 100%;
  }
  
  h1, h2, h3, h4, h5, h6, p, span, small {
    margin-bottom: 0;
  }
  label, input, textarea, select, button {
    font-family: inherit;
  }
  
  textarea {
    overflow-wrap: break-word;
  }
  
  //Reset Css

  /* Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property */
  *:where(:not(iframe, canvas, img, svg, video, strong):not(svg *)) {
    all: unset;
    display: revert;
  }

  /* Preferred box-sizing value */
  *, *::before, *::after {
    box-sizing: border-box;
  }

  /* Remove list styles (bullets/numbers) */
  ol, ul {
    list-style: none;
  }

  /* For images to not be able to exceed their container */
  img {
    max-width: 100%;
  }
  
  svg {
    flex-shrink: 0;
  }

  /* Removes spacing between cells in tables */
  table {
    border-collapse: collapse;
  }

  /* Revert the 'white-space' property for textarea elements on Safari */
  textarea {
    white-space: revert;
  }
  
  /* Change the white to any color */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px #141716 inset !important;
    -webkit-text-fill-color: ${palette.light.neutral.white}; 
    -webkit-textfield-decoration-container: none !important;
    caret-color: ${palette.light.neutral.white};
    color-scheme: dark; 
  }

  /* --- Google Maps Autocomplete Dropdown Styles --- */
  .pac-container {
    background-color: ${palette.light.primary[950]};
    border-radius: 8px; 
    color: ${palette.light.neutral.white};
    border: none;
    padding: 8px 6px;
  }
  
  .pac-container.pac-logo.hdpi::after,
  .pac-logo::after {
    margin: 8px 8px 0 0;
    filter: invert(0.1) brightness(10);
  }
  
  .pac-item {
    padding: 12px;
    border: none;
    border-radius: 8px;
    box-shadow: none;  
    color: ${palette.light.primary[300]};
  }
  
  .pac-item:hover,
  .pac-item-selected {
    background-color: ${palette.light.primary[900]};
  }
  
  .pac-matched-text,
  .pac-item-query {
    color: ${palette.light.neutral.white};
  }
  
  .pac-icon {
    display: none;
  }

  /* --- Rechart reset styles --- */
  .recharts-surface {
    overflow: visible !important;
  }

`

export default withTheme(ResetStyles)
