import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {StyledChartsSubtitle} from '@/features/quote/components/saving-quote/style.ts'
import {
    StyledLegend,
    StyledLegendWrap,
    StyledPerformanceChart
} from '@/features/quote/components/performance-chart/performance-chart/style.ts'
import {Bar, BarProps, CartesianGrid, ComposedChart, ResponsiveContainer, YAxis} from 'recharts'
import {CustomBarSaving} from '@/features/quote/components/saving-quote/CustomBarSaving.tsx'
import {useTranslation} from 'react-i18next'
import {useTheme} from 'styled-components'
import {QuoteCalculationsType} from '@/features/quote/types.ts'

export const FirstChartColumn = ({
    monthlyAverageSpent
}: {
    monthlyAverageSpent: QuoteCalculationsType['monthlyAverageSpent']
}) => {
    const {t} = useTranslation()
    const theme = useTheme()

    const data = [
        {
            name: 'montly average spent',
            old: Math.round(monthlyAverageSpent.old * 100) / 100,
            new: Math.round(monthlyAverageSpent.new * 100) / 100
        }
    ]

    return (
        <Flexbox style={{width: '100%', height: 400}} direction={'column'} gap={4}>
            <StyledChartsSubtitle>{t('quote:saving:saving_first_year')}</StyledChartsSubtitle>

            <StyledPerformanceChart direction="column" gap={3}>
                <StyledLegendWrap gap={2}>
                    <StyledLegend gap={1} align="center" color={'#E4E7EC'}>
                        <span />
                        <p>{t('quote:saving:legend_old')}</p>
                    </StyledLegend>

                    <StyledLegend gap={1} align="center" color={theme.palette.primary[500]}>
                        <span />
                        <p>{t('quote:saving:legend_new')}</p>
                    </StyledLegend>
                </StyledLegendWrap>

                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart data={data}>
                        <YAxis
                            fontSize={12}
                            stroke={theme.palette.primary[600]}
                            strokeWidth={0}
                            label={{
                                value: t('quote:saving:monthly_expense'),
                                angle: -90,
                                position: 'insideLeft',
                                dx: -10,
                                dy: -20,
                                style: {textAnchor: 'middle', fill: theme.palette.primary[600]}
                            }}
                        />

                        <CartesianGrid vertical={false} stroke={theme.palette.primary[100]} />

                        <Bar dataKey="old" shape={(props: BarProps) => <CustomBarSaving {...props} />} fill="#e0e0e0" />

                        <Bar dataKey="new" shape={(props: BarProps) => <CustomBarSaving {...props} />} fill="#82ca9d" />
                    </ComposedChart>
                </ResponsiveContainer>
            </StyledPerformanceChart>
        </Flexbox>
    )
}
