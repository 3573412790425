import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import arya_logo from '@/assets/images/arya-green.png'

export const StyledTitleOperationSystem = styled.h1(
    ({theme: {typography, palette}}) => css`
        ${typography.displaySm};
        font-weight: 500;
        color: ${palette.primary[950]};
    `
)

export const StyleContainerOperationSystem = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        width: 100%;
        padding: 0 ${spacing * 14}px 0 ${spacing * 14}px;
    `
)
export const StyledIconRowContainer = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: ${spacing * 4}px;
        width: 100%;
    `
)

export const StyleCardSystem = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        gap: ${spacing * 3}px;
        flex: 1 0 0;
        align-self: stretch;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 32px;
    `
)

export const StylePdfCardTitle = styled.p(
    ({theme: {palette}}) => css`
        font-weight: 600;
        color: ${palette.primary[950]};
        margin-bottom: 16px;
    `
)

export const StyledAcceptQuoteContainer = styled.div(
    ({theme: {palette, typography, spacing}}) => css`
        & h4 {
            font-weight: 500;
            color: ${palette.neutral[950]};
            ${typography.displaySm};
        }

        display: flex;
        flex-direction: column;
        gap: ${spacing * 10}px;
        width: 100%;
        margin-top: 56px;
    `
)
export const StyleInfoContainer = styled.div(
    () => css`
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-template-rows: auto auto;
        grid-template-areas:
            'name signature'
            'date empty';
        grid-row-gap: 50px;
        grid-column-gap: 50px;
        margin-top: 20px;
    `
)

export const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

export const Label = styled.div`
    color: black;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
    width: 98px;
    height: 68px;
    flex-shrink: 0;
`

export const Underline = styled.div`
    width: 100%;
    height: 1px;
    background-color: #ddd;
    margin-top: 2px;
`

export const StyledPdfFooter = styled.div(
    () => css`
        display: flex;
        width: 100%;
        height: 174px;
        padding: 56px;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 56px 56px 0px 0px;
        background: linear-gradient(45deg, #1e2322 0%, #5f937d 100%);
        margin-top: 56px;
    `
)
export const StyledFooterLogoWrapper = styled.div(
    () => css`
        margin: 0 auto;

        & > img {
            width: 57.026px;
            height: 17.546px;
        }
    `
)
export const StyledAryaLogo = styled.div(
    () => css`
        background: url(${arya_logo}) lightgray 50% / cover no-repeat;
    `
)

export const StyledCheckBoxToAcceptQuote = styled.div(
    ({theme: {spacing, palette}}) => css`
        .accept-quote-checkbox {
            margin-top: ${spacing * 2}px;
            & label {
                color: ${palette.neutral[950]};
                font-weight: 500;
            }
        }
    `
)
