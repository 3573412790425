import {z} from 'zod'
import {
    EnergyPayloadSchema,
    MonthlyConsumptionResponseSchema,
    ProjectSystemsSchema,
    SendElectricityConsumptionPayloadSchema
} from './services/project.schema'

export enum TimeRangeE {
    HalfYearly = 'halfYearly',
    Monthly = 'monthly',
    Daily = 'daily'
}

export enum InputTimeRangeE {
    Annual = 'annual',
    BiMonthly = 'bi-monthly',
    Monthly = 'monthly'
}

export enum SectionVariantE {
    Form = 'form',
    Chart = 'chart'
}

export enum ConsumptionInputTypesE {
    Consumption = 'consumption',
    Bill = 'bill',
    SiteType = 'siteType'
}

export type EnergyPayloadType = z.infer<typeof EnergyPayloadSchema>
export type MonthlyConsumptionResponseType = z.infer<typeof MonthlyConsumptionResponseSchema>
export type SendElectricityConsumptionPayloadType = z.infer<typeof SendElectricityConsumptionPayloadSchema>
export type ProjectSystemsType = z.infer<typeof ProjectSystemsSchema>
