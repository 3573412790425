import {StyledChartsWrapper, StyledSavingQuotesWrapper} from '@/features/quote/components/saving-quote/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useTranslation} from 'react-i18next'
import {SolarTable} from '@/features/quote/components/saving-quote/SolarTable.tsx'
import {useState} from 'react'
import {FirstChartColumn} from '@/features/quote/components/saving-quote/FirstChartColumn.tsx'
import {SecondChartColumn} from '@/features/quote/components/saving-quote/SecondChartColumn.tsx'
import {QuoteCalculationsType} from '@/features/quote/types.ts'

export const SavingQuote = ({quoteCalculationsData}: {quoteCalculationsData: QuoteCalculationsType}) => {
    const {t} = useTranslation()
    const isPDF = new URLSearchParams(location.search).get('pdf') === 'true'
    const [openTable, setOpenTable] = useState(isPDF)

    return (
        <StyledSavingQuotesWrapper direction={'column'} gap={4}>
            <Flexbox justify={'space-between'} align="center" fullWidth>
                <h1 className={'title'}>{t('quote:saving:title')}</h1>

                {!isPDF && (
                    <p className={'table'} onClick={() => setOpenTable(!openTable)}>
                        {t('quote:saving:saving_table')}
                    </p>
                )}
            </Flexbox>

            <StyledChartsWrapper>
                <FirstChartColumn monthlyAverageSpent={quoteCalculationsData.monthlyAverageSpent} />

                <SecondChartColumn cumulativeSavings={quoteCalculationsData.cumulativeSavings} />
            </StyledChartsWrapper>

            {openTable && <SolarTable data={quoteCalculationsData.periodSavingsData} />}
        </StyledSavingQuotesWrapper>
    )
}
