import {useQuery} from '@tanstack/react-query'
import {AUTH_QUERY_KEYS} from './keys'
import {AUTH_API} from '../services/auth.http'
import {useAuthStore} from '../store/useAuthStore'

export const useMe = () => {
    const accessToken = useAuthStore(state => state.accessToken)

    return useQuery({
        queryKey: [AUTH_QUERY_KEYS.profile],
        queryFn: () => AUTH_API.me(),
        enabled: !!accessToken
    })
}
