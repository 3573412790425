import {FC, ReactNode} from 'react'
import {StyledInputWrapper} from '@components/ui/input-wrapper/style.ts'

interface InputWrapperProps {
    children: ReactNode
    className?: string
    hasError: boolean
}

export const InputWrapper: FC<InputWrapperProps> = ({children, className, hasError}) => {
    return (
        <StyledInputWrapper className={className} $hasError={hasError} align="center" gap={1}>
            {children}
        </StyledInputWrapper>
    )
}

InputWrapper.displayName = 'InputWrapper'
