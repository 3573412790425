import React from 'react'
import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {ChargingStationIcon} from '@/features/quote/components/operation-system/icons/ChargingStationIcon.tsx'
import {QuoteCalculationsType, QuoteType} from '@/features/quote/types.ts'
import {useTranslation} from 'react-i18next'
import {SolarPanelIconPdf} from './icons/SolarPanelIconPdf'
import {HomeIconPdf} from './icons/HomeIconPdf'
import {ElectricityGridIconPdf} from './icons/ElectricityGridIconPdf'
import {BatteryIconPdf} from './icons/BatteryIconPdf'
import {StyleCardSystem, StylePdfCardTitle} from './style'
import {DefaultNamespace} from 'i18next'

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 70px);
    grid-template-rows: repeat(3, 70px);
    justify-items: center;
    align-items: center;
    max-width: 375px;
    gap: 10px;
    position: relative;
    margin: 20px 40px 55px 40px;

    .home {
        grid-area: 1 / 2 / 2 / 3;
        & svg {
            width: 40px;
            height: 40px;
        }
    }

    .electricity-grid {
        grid-area: 2 / 3 / 3 / 4;
        & svg {
            width: 40px;
            height: 40px;
        }
    }

    .battery {
        grid-area: 3 / 2 / 4 / 3;
        & svg {
            width: 40px;
            height: 40px;
        }
    }

    .solar-energy {
        grid-area: 2 / 1 / 3 / 2;
        & svg {
            width: 40px;
            height: 40px;
        }
    }

    .center-road {
        grid-area: 2 / 2 / 3 / 3;

        & > svg {
            width: 100px;
            height: 100px;
        }
    }
`

const Label = styled.div(
    ({theme: {typography, palette}}) => css`
        ${typography.textSm};
        font-weight: 500;
        color: ${palette.primary[700]};
    `
)

const EnergyText = styled.div(
    ({theme: {typography, palette}}) => css`
        ${typography.textSm};
        font-weight: 500;
        color: ${palette.primary[500]};
    `
)

export const BaseIconPdf = ({
    children,
    quoteCalculationsData,
    quoteData,
    title
}: {
    children: React.ReactNode
    quoteCalculationsData: QuoteCalculationsType
    quoteData: QuoteType['projectSystem']
    title: DefaultNamespace
}) => {
    const {t} = useTranslation()
    return (
        <div>
            <StylePdfCardTitle style={{color: 'black'}}>{title}</StylePdfCardTitle>
            <StyleCardSystem>
                <GridContainer>
                    <Flexbox className={'solar-energy'} direction={'column'} gap={2} align={'center'}>
                        <SolarPanelIconPdf />
                        <Label
                            style={{
                                position: 'absolute',
                                top: '140px',
                                textAlign: 'center'
                            }}
                        >
                            {t('quote:operation_system:solar_energy')}
                        </Label>
                        <EnergyText
                            style={{
                                position: 'absolute',
                                top: '180px'
                            }}
                        >
                            {quoteCalculationsData?.systemSize} kW
                        </EnergyText>
                    </Flexbox>

                    <Flexbox
                        className={'electricity-grid'}
                        direction={'column'}
                        gap={2}
                        align={'center'}
                        justify={'start'}
                    >
                        <ElectricityGridIconPdf />
                        <Label
                            style={{
                                position: 'absolute',
                                top: '140px'
                            }}
                        >
                            {t('quote:operation_system:electricity_grid')}
                        </Label>
                    </Flexbox>

                    <Flexbox className={'home'} direction={'column'} gap={2} align={'center'}>
                        <Label>{t('quote:operation_system:house')}</Label>
                        <HomeIconPdf />
                    </Flexbox>

                    <Flexbox className={'battery'} gap={2} align={'flex-start'}>
                        {quoteData.chargingStationProductId && <ChargingStationIcon />}
                        {(quoteData.batteryProductId || quoteData.type === 'cerbero') && (
                            <Flexbox gap={2} align={'center'} direction={'column'}>
                                <BatteryIconPdf />
                                <Label
                                    style={{
                                        position: 'absolute',
                                        top: '220px'
                                    }}
                                >
                                    {t('quote:operation_system:battery')}
                                </Label>
                                <EnergyText
                                    style={{
                                        position: 'absolute',
                                        top: '240px'
                                    }}
                                >
                                    {quoteData.type === 'cerbero'
                                        ? '2,56'
                                        : quoteCalculationsData?.effectiveAccumulation}{' '}
                                    kW
                                </EnergyText>
                            </Flexbox>
                        )}
                    </Flexbox>

                    <Flexbox className={'center-road'}>{children}</Flexbox>
                </GridContainer>
            </StyleCardSystem>
        </div>
    )
}

export default BaseIconPdf
