import styled, {css} from 'styled-components'
import backgroundImage from '@assets/images/environment_benefits.png'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledWrapperBenefits = styled.div(
    ({theme: {spacing}}) => css`
        width: 100%;
        padding: 0 ${spacing * 14}px;
        margin-top: ${spacing * 5}px;
    `
)

export const StyledBenefitsContainer = styled.div(
    ({theme: {spacing}}) => css`
        position: relative;
        border-radius: 24px;
        background-image: url(${backgroundImage});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        padding: ${spacing * 14}px ${spacing * 10}px;
        overflow: hidden;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.4);
            border-radius: 24px;
            z-index: 1;
        }

        > * {
            position: relative;
            z-index: 2;
        }
    `
)

export const StyledEnvironmentTitle = styled.h1(
    ({theme: {typography}}) => css`
        ${typography.displaySm};
        font-weight: 500;
    `
)

export const StyledEnvironmentBenefitsListContainer = styled.div(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 6}px 0;
    `
)

export const StyledEnvironmentBenefitsList = styled(Flexbox)(
    ({theme: {spacing, palette}}) => css`
        padding: ${spacing * 6}px 0;
        border-top: 1px solid ${palette.neutral.white};
        border-bottom: 1px solid ${palette.neutral.white};
    `
)

export const StyledSectionTitle = styled.p(
    ({theme: {typography}}) => css`
        ${typography.displayXs};
        font-weight: 500;
    `
)

export const StyledBenefitsData = styled.p(
    ({theme: {typography}}) => css`
        ${typography.displayLg};
        font-weight: 600;
    `
)

export const StyledBenefitsDescription = styled.p(
    ({theme: {typography}}) => css`
        ${typography.textMd};
        font-weight: 400;
    `
)

export const StyledInfo = styled.p(
    ({theme: {typography}}) => css`
        ${typography.textMd};
        font-weight: 500;
    `
)
