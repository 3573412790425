import {Outlet, useMatch} from 'react-router-dom'
import {StyledCommonLayout} from './style.ts'
import React from 'react'
import {NavBar} from '@/components/commons/nav-bar/NavBar.tsx'
import {ROUTE_SYSTEM_CONFIGURATION} from '@/utilities/constants/routeNames.ts'
import useWindowDimensions from '@/hooks/useWindowDimensions.ts'
import {Container} from '@/components/ui/container/Container.tsx'

export const CommonLayout: React.FC = () => {
    const match = useMatch(ROUTE_SYSTEM_CONFIGURATION)
    const hideNavbar = !!match
    const {height} = useWindowDimensions()
    return (
        <StyledCommonLayout $hideNavbar={hideNavbar} $vh={height}>
            {!hideNavbar && <NavBar />}
            <Container fullHeight fullWidth>
                <Outlet />
            </Container>
        </StyledCommonLayout>
    )
}
