export const OptimizerIcon = () => {
    return (
        <svg width="62" height="61" viewBox="0 0 62 61" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_2816_45573)">
                <rect x="3" width="56" height="56" rx="11.2" fill="url(#paint0_linear_2816_45573)" />
                <rect x="3.56" y="0.56" width="54.88" height="54.88" rx="10.64" stroke="#6CB691" stroke-width="1.12" />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M40.4327 15.2692C41.0835 15.2692 41.7067 15.5277 42.1664 15.9874C42.6261 16.4472 42.8846 17.0704 42.8846 17.7212V30.7981C42.8846 31.4489 42.6261 32.0721 42.1664 32.5318C41.7067 32.9915 41.0835 33.25 40.4327 33.25H22.4519C21.8011 33.25 21.1779 32.9915 20.7182 32.5318C20.2585 32.0721 20 31.4489 20 30.7981V17.7212C20 17.0704 20.2585 16.4472 20.7182 15.9874C21.1779 15.5277 21.8011 15.2692 22.4519 15.2692V14.4519C22.4519 13.0972 23.5492 12 24.9038 12H26.5385C27.8932 12 28.9904 13.0972 28.9904 14.4519V15.2692H33.8942V14.4519C33.8942 13.0972 34.9915 12 36.3462 12H37.9808C39.3355 12 40.4327 13.0972 40.4327 14.4519V15.2692ZM38.7981 15.2692V14.4519C38.7981 14.0004 38.4323 13.6346 37.9808 13.6346H36.3462C35.8946 13.6346 35.5288 14.0004 35.5288 14.4519V15.2692H38.7981ZM27.3558 15.2692V14.4519C27.3558 14.0004 26.99 13.6346 26.5385 13.6346H24.9038C24.4523 13.6346 24.0865 14.0004 24.0865 14.4519V15.2692H27.3558ZM40.4327 16.9038H22.4519C22.2353 16.9038 22.0269 16.9897 21.8737 17.1429C21.7204 17.2962 21.6346 17.5046 21.6346 17.7211V30.7981C21.6346 31.0147 21.7204 31.2231 21.8737 31.3763C22.0269 31.5295 22.2353 31.6154 22.4519 31.6154H40.4327C40.6493 31.6154 40.8577 31.5295 41.0109 31.3763C41.1642 31.2231 41.25 31.0146 41.25 30.7981V17.7211C41.25 17.5046 41.1642 17.2961 41.0109 17.1429C40.8577 16.9897 40.6493 16.9038 40.4327 16.9038Z"
                    fill="white"
                />
                <path d="M30 33C29.6667 33.5 29 35.2 29 38" stroke="white" stroke-width="1.5" stroke-linecap="round" />
                <path
                    d="M32.9997 33C32.6664 34.1667 31.9997 37.2 31.9997 40C31.9997 43.5 38.4997 45 38.9997 39C39.4997 33 28.9997 37.5 30.9997 45"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                />
                <rect x="29" y="45" width="4" height="3" rx="1" fill="white" />
            </g>
            <defs>
                <filter
                    id="filter0_d_2816_45573"
                    x="0.76"
                    y="0"
                    width="60.48"
                    height="60.48"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2.24" />
                    <feGaussianBlur stdDeviation="1.12" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2816_45573" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2816_45573" result="shape" />
                </filter>
                <linearGradient
                    id="paint0_linear_2816_45573"
                    x1="2.99913"
                    y1="55.9997"
                    x2="58.9993"
                    y2="-0.000681827"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#1E2322" />
                    <stop offset="1" stop-color="#5F937D" />
                </linearGradient>
            </defs>
        </svg>
    )
}
