import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledPerformanceChart = styled(Flexbox)(
    ({theme: {palette, spacing}}) => css`
        width: 100%;
        height: 100%;
        color: ${palette.primary[900]};
        //
        padding: ${spacing * 6}px;
        border-radius: 32px;
        border: 1px solid #0000001a;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        min-height: 234px;
    `
)

export const StyledLegend = styled(Flexbox)<{color: string}>(
    ({color, theme: {palette}}) => css`
        & span {
            height: 8px;
            width: 8px;
            border-radius: 50%;
            background-color: ${color};
        }
        & p {
            color: ${palette.primary[700]};
        }
    `
)

export const StyledLegendWrap = styled(Flexbox)(
    () => css`
        margin-left: auto;
    `
)
