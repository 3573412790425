import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import {initReactI18next} from 'react-i18next'
//translations
// import en from './en/en.json'
import it from './it/it.json'

import 'dayjs/locale/en'
import 'dayjs/locale/it'
export const FALLBACK_LANGUAGE = 'it'

export const APP_LANGUAGES = [
    //TODO: decomment if needed multi-lang
    // {label: 'English (EN)', code: 'en', flagCode: 'gb', json: en},
    {label: 'Italiano (IT)', code: 'it', flagCode: 'it', json: it}
] as const

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: Object.fromEntries(APP_LANGUAGES.map(language => [language.code, language.json])),
        ns: [],
        defaultNS: '',
        keySeparator: false,
        fallbackLng: FALLBACK_LANGUAGE,
        debug: false,
        react: {
            useSuspense: false
        },
        supportedLngs: APP_LANGUAGES.map(language => language.code),
        interpolation: {
            escapeValue: false
        },
        detection: {
            order: ['localStorage', 'navigator'],
            lookupLocalStorage: 'i18nextLng'
        }
    })

export const changeAppLanguage = async (nextLanguage: (typeof APP_LANGUAGES)[number]['code']) => {
    await i18n.changeLanguage(nextLanguage)
}

export default i18n
