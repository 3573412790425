import {useTranslation} from 'react-i18next'
import {toast} from '@utilities/toast/toast.tsx'
import {useEditProfile} from '../../queries/useEditProfile'
import {Button} from '@/components/ui/button/Button'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {SubmitHandler, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {
    StyledButton,
    StyledButtonWrapper,
    StyledDescription,
    StyledForm,
    StyledInputContainer,
    StyledInputWrapper,
    StyledPasswordButton,
    StyledPasswordContainer
} from './style'
import {EditProfileValidationSchema, PROFILE_USER_MODEL} from '../../services/profile.schema'
import {EditProfilePayloadType} from '../../types'
import {InputText} from '@/components/commons/input-text/InputText'
import {useEffect} from 'react'
import {errorHandler} from '@/utilities/helpers'
import {useLogout} from '@/features/auth/queries/useLogout'
import {ArrowRightIcon, CheckIcon, LogOut02Icon} from '@/assets/icons/icons'
import {useTheme} from 'styled-components'
import {UserType} from '@/features/auth/types'

type EditProfileProps = {
    onClose: () => void
    openChangePasswordModal: () => void
    user?: Partial<UserType>
}

export const EditProfileForm: React.FC<EditProfileProps> = ({onClose, openChangePasswordModal, user}) => {
    const {t} = useTranslation()
    const {palette} = useTheme()
    const {mutate: logout, isPending: isPendingLogout, isError: isErrorLogout, error: errorLogout} = useLogout(onClose)

    const onSuccessEditProfile = () => {
        toast.success(t('edit_profile:profile_updated'))
        onClose()
    }

    const {
        mutate: editProfile,
        isPending,
        isError: isErrorEditProfile,
        error: errorEditProfile
    } = useEditProfile({onSuccess: onSuccessEditProfile})

    const {
        register,
        handleSubmit,
        setError,
        formState: {errors}
    } = useForm<EditProfilePayloadType>({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        resolver: zodResolver(EditProfileValidationSchema),
        defaultValues: {
            [PROFILE_USER_MODEL.Email]: user?.email,
            [PROFILE_USER_MODEL.FirstName]: user?.firstName,
            [PROFILE_USER_MODEL.LastName]: user?.lastName,
            [PROFILE_USER_MODEL.PhoneNumber]: user?.phoneNumber ?? undefined,
            [PROFILE_USER_MODEL.Qualification]: user?.qualification ?? undefined,
            [PROFILE_USER_MODEL.CompanyName]: user?.companyName ?? undefined,
            [PROFILE_USER_MODEL.VatNumber]: user?.vatNumber ? `${user?.vatNumber}` : undefined
        }
    })

    useEffect(() => {
        if (isErrorLogout || isErrorEditProfile) {
            errorHandler({error: errorLogout || errorEditProfile, setError})
        }
    }, [isErrorLogout, isErrorEditProfile])

    const onSubmit: SubmitHandler<EditProfilePayloadType> = data => {
        editProfile(data)
    }

    return (
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
            {(isPending || isPendingLogout) && <Spinner />}
            <StyledDescription>{t('edit_profile:description')}</StyledDescription>
            <StyledInputContainer>
                <InputText
                    type={'text'}
                    label={`${t('commons:name')} *`}
                    placeholder={t('create_project_form:name_placeholder')}
                    errorMessage={t(errors[PROFILE_USER_MODEL.FirstName]?.message || '')}
                    defaultValue={user?.firstName}
                    {...register(PROFILE_USER_MODEL.FirstName)}
                />
                <InputText
                    type={'text'}
                    label={`${t('commons:surname')} *`}
                    placeholder={t('create_project_form:surname_placeholder')}
                    errorMessage={t(errors[PROFILE_USER_MODEL.LastName]?.message || '')}
                    defaultValue={user?.lastName}
                    {...register(PROFILE_USER_MODEL.LastName)}
                />

                <InputText
                    type={'text'}
                    label={`${t('commons:phone')} *`}
                    placeholder={t('create_project_form:phone_placeholder')}
                    errorMessage={t(errors[PROFILE_USER_MODEL.PhoneNumber]?.message || '')}
                    defaultValue={user?.phoneNumber ?? undefined}
                    {...register(PROFILE_USER_MODEL.PhoneNumber)}
                />
                <InputText
                    type={'email'}
                    label={`${t('commons:email')} *`}
                    placeholder={t('create_project_form:email_placeholder')}
                    errorMessage={t(errors[PROFILE_USER_MODEL.Email]?.message || '')}
                    defaultValue={user?.email}
                    disabled
                    {...register(PROFILE_USER_MODEL.Email)}
                />
            </StyledInputContainer>
            <div>
                <StyledInputWrapper>
                    <InputText
                        type={'text'}
                        label={t('commons:qualification')}
                        placeholder={t('edit_profile:qualification_placeholder')}
                        errorMessage={t(errors[PROFILE_USER_MODEL.Qualification]?.message || '')}
                        defaultValue={user?.qualification ?? undefined}
                        {...register(PROFILE_USER_MODEL.Qualification)}
                    />
                    <InputText
                        type={'text'}
                        label={`${t('commons:agency')} *`}
                        placeholder={t('edit_profile:agency_placeholder')}
                        errorMessage={t(errors[PROFILE_USER_MODEL.CompanyName]?.message || '')}
                        defaultValue={user?.companyName ?? undefined}
                        {...register(PROFILE_USER_MODEL.CompanyName)}
                    />
                    <InputText
                        type={'text'}
                        label={t('commons:client_vat')}
                        placeholder={t('edit_profile:vat_placeholder')}
                        helpText={t('edit_profile:vat_hint')}
                        errorMessage={t(errors[PROFILE_USER_MODEL.VatNumber]?.message || '')}
                        defaultValue={user?.vatNumber ?? undefined}
                        {...register(PROFILE_USER_MODEL.VatNumber)}
                    />
                </StyledInputWrapper>
                <StyledDescription>{t('edit_profile:change_password')}</StyledDescription>
            </div>

            <StyledPasswordContainer>
                <p>{t('edit_profile:change_password_description')}</p>

                <StyledPasswordButton
                    variant="secondary"
                    onClick={e => {
                        e.preventDefault()
                        openChangePasswordModal()
                    }}
                    type="button"
                >
                    {t('edit_profile:change_password')}
                    <ArrowRightIcon />
                </StyledPasswordButton>
            </StyledPasswordContainer>

            <StyledButtonWrapper>
                <StyledButton onClick={() => logout()} variant="secondary" type="button">
                    <LogOut02Icon />
                    {t('commons:logout')}
                </StyledButton>
                <Button type="submit" disabled={isPending}>
                    {t('commons:confirm_changes')}
                    <CheckIcon stroke={isPending ? palette.primary[700] : palette.neutral.white} />
                </Button>
            </StyledButtonWrapper>
        </StyledForm>
    )
}
EditProfileForm.displayName = 'EditProfileForm'
