import styled from 'styled-components'

type StyledCommonLayoutProps = {
    $hideNavbar: boolean
    $vh: number
}

export const StyledCommonLayout = styled.div<StyledCommonLayoutProps>`
    height: ${({$vh}) => `${$vh}px`};
    width: 100%;
    display: grid;
    grid-template-columns: ${({$hideNavbar}) => ($hideNavbar ? 'auto' : '0fr 1fr')};
`
