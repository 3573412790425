import {forwardRef, ReactNode} from 'react'
import {NavLinkProps} from 'react-router-dom'
import {ButtonStyleProps} from '@components/ui/button/Button.tsx'
import {StyledButtonNavLink} from '@components/ui/button-nav-link/style.ts'

export type ButtonNavLinkProps = Omit<NavLinkProps, 'children' | 'className' | 'style'> &
    ButtonStyleProps & {
        children?: ReactNode
        className?: HTMLAnchorElement['className']
    }

export const ButtonNavLink = forwardRef<HTMLAnchorElement, ButtonNavLinkProps>(({to, ...props}, ref) => (
    <StyledButtonNavLink ref={ref} to={to} {...props} />
))
ButtonNavLink.displayName = 'ButtonNavLink'
