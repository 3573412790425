import {useMutation, useQueryClient} from '@tanstack/react-query'
import {PROFILE_MUTATION_KEYS} from './keys'
import {PROFILE_API} from '../services/profile.http'
import {EditProfilePayloadType} from '../types'
import {AUTH_QUERY_KEYS} from '@/features/auth/queries/keys'

type useEditProfileProps = {
    onSuccess: () => void
}

export const useEditProfile = ({onSuccess}: useEditProfileProps) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey: [PROFILE_MUTATION_KEYS.editProfile],
        mutationFn: (body: EditProfilePayloadType) => PROFILE_API.editProfile(body),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [AUTH_QUERY_KEYS.profile]})
            onSuccess?.()
        }
    })
}
