import {Spinner} from '@/components/ui/spinner/Spinner'
import {formatValues} from '@/features/project-details/helpers/helpers'
import {ComponentsQuote} from '@/features/quote/components/components-quote/ComponentsQuote'
import {EnvironmentalBenefits} from '@/features/quote/components/environmental-benefits-section/EnvironmentalBenefits'
import {FinancialImpact} from '@/features/quote/components/financial-impact/FinancialImpact'
import {OperationSystemPdf} from '@/features/quote/components/operation-system/OperationSystemPdf'
import {PerformanceChartSection} from '@/features/quote/components/performance-chart/PerformanceChartSection'
import {SavingQuote} from '@/features/quote/components/saving-quote/SavingQuote'
import {SummaryQuote} from '@/features/quote/components/summary-quote/SummaryQuote'
import {StyledPdfQuotePageWrapper} from '@/features/quote/components/summary-quote/style'
import {useGetQuote} from '@/features/quote/queries/useGetQuote'
import {useGetQuoteCalculations} from '@/features/quote/queries/useGetQuoteCalculations'
import {errorHandler} from '@/utilities/helpers'
import {useEffect} from 'react'
import {useParams} from 'react-router-dom'

const QuotePDF = () => {
    const {quoteId} = useParams()

    const {
        data: quoteData,
        isLoading: isQuoteLoading,
        error: quoteError,
        isError: isQuoteError
    } = useGetQuote({quoteId})

    const {
        data: quoteCalculationsData,
        isLoading: isQuoteCalculationLoading,
        error: quoteCalculationError,
        isError: isQuoteCalculationError
    } = useGetQuoteCalculations({quoteId})

    useEffect(() => {
        if (isQuoteError || isQuoteCalculationError) {
            errorHandler({error: quoteError || quoteCalculationError})
        }
    }, [isQuoteError, isQuoteCalculationError])

    if (isQuoteLoading || isQuoteCalculationLoading) return <Spinner />

    return (
        <>
            {quoteData && quoteCalculationsData && (
                <StyledPdfQuotePageWrapper>
                    <SummaryQuote
                        quoteData={quoteData}
                        systemSize={quoteCalculationsData?.systemSize}
                        quoteFinalPrice={formatValues(quoteCalculationsData?.quoteFinalPrice)}
                        estimatedAnnualSavings={quoteCalculationsData?.estimatedAnnualSavings}
                    />

                    <ComponentsQuote quoteData={quoteData} quoteCalculationsData={quoteCalculationsData} />
                    <PerformanceChartSection quoteCalculationsData={quoteCalculationsData} />
                    <SavingQuote quoteCalculationsData={quoteCalculationsData} />
                    <FinancialImpact quoteCalculationsData={quoteCalculationsData} />
                    <EnvironmentalBenefits quoteCalculationsData={quoteCalculationsData} />
                    <OperationSystemPdf quoteCalculationsData={quoteCalculationsData} quoteData={quoteData} />
                </StyledPdfQuotePageWrapper>
            )}
        </>
    )
}

export default QuotePDF
