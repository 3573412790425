import styled, {css} from 'styled-components'

export const StyledDescription = styled.p`
    ${({theme: {typography, palette}}) => css`
        ${typography.displayXs}
        max-width: 330px;
        color: ${palette.primary[25]};
    `}
`

export const StyledButtonWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: ${spacing * 4}px;
        margin: ${spacing * 4}px 0px;
    `}
`
