import styled, {css} from 'styled-components'

export const StyledLabel = styled.label<{$hasError?: boolean}>`
    ${({theme: {palette, typography}, $hasError}) => css`
        display: block;
        color: ${$hasError ? palette.danger[400] : palette.primary[300]};
        font-weight: 500;
        text-align: start;
        ${typography.textSm}
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    `}
`
