export const FirstTabFirstAnimation = (
    <svg width="245" height="179" viewBox="0 0 245 179" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M122.5 2V177" stroke="#D8D8D8" stroke-width="3" stroke-linecap="round" />
        <path
            d="M2 89.5L243 89.5"
            stroke="url(#paint0_linear_2455_59167)"
            stroke-width="3"
            stroke-linecap="round"
            stroke-dasharray="10 10"
        >
            <animate attributeName="stroke-dashoffset" from="0" to="-20" dur="0.5s" repeatCount="indefinite" />
        </path>
        <path
            d="M1.99999 79.4996L104 79.4996C108.418 79.4996 112 75.9179 112 71.4996L112 2.00001"
            stroke="url(#paint1_linear_2455_59167)"
            stroke-width="3"
            stroke-linecap="round"
            stroke-dasharray="10 10"
        >
            <animate attributeName="stroke-dashoffset" from="0" to="-20" dur="0.5s" repeatCount="indefinite" />
        </path>
        <path
            d="M1.99999 99.5002L104 99.5001C108.418 99.5001 112 103.082 112 107.5L112 177"
            stroke="url(#paint2_linear_2455_59167)"
            stroke-width="3"
            stroke-linecap="round"
            stroke-dasharray="10 10"
        >
            <animate attributeName="stroke-dashoffset" from="0" to="-20" dur="0.5s" repeatCount="indefinite" />
        </path>
        <path
            d="M243 99.5002L141 99.5001C136.582 99.5001 133 103.082 133 107.5L133 177"
            stroke="#D8D8D8"
            stroke-width="3"
            stroke-linecap="round"
        />
        <path
            d="M243 79.4996L141 79.4996C136.582 79.4996 133 75.9179 133 71.4996L133 2.00001"
            stroke="#D8D8D8"
            stroke-width="3"
            stroke-linecap="round"
        />
        <defs>
            <linearGradient
                id="paint0_linear_2455_59167"
                x1="2"
                y1="89.5"
                x2="243"
                y2="89.5"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#5F937D" />
                <stop offset="1" stop-color="#6CB691" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_2455_59167"
                x1="2"
                y1="2"
                x2="112"
                y2="2.00001"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#5F937D" />
                <stop offset="1" stop-color="#6CB691" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_2455_59167"
                x1="2"
                y1="177"
                x2="112"
                y2="177"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#5F937D" />
                <stop offset="1" stop-color="#6CB691" />
            </linearGradient>
        </defs>
    </svg>
)

export const SecondTabFirstAnimation = (
    <svg width="245" height="179" viewBox="0 0 245 179" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M122.5 2V177" stroke="#D8D8D8" stroke-width="3" stroke-linecap="round" />
        <path d="M2 89.5L243 89.5" stroke="#D8D8D8" stroke-width="3" stroke-linecap="round" />
        <path
            d="M1.99999 79.4996L104 79.4996C108.418 79.4996 112 75.9179 112 71.4996L112 2.00001"
            stroke="url(#paint0_linear_2455_59167)"
            stroke-width="3"
            stroke-linecap="round"
            stroke-dasharray="10 10"
        >
            <animate attributeName="stroke-dashoffset" from="0" to="-20" dur="0.5s" repeatCount="indefinite" />
        </path>
        <path
            d="M1.99999 99.5002L104 99.5001C108.418 99.5001 112 103.082 112 107.5L112 177"
            stroke="#D8D8D8"
            stroke-width="3"
            stroke-linecap="round"
        />
        <path
            d="M243 99.5002L141 99.5001C136.582 99.5001 133 103.082 133 107.5L133 177"
            stroke="#D8D8D8"
            stroke-width="3"
            stroke-linecap="round"
        />
        <path
            d="M243 79.4996L141 79.4996C136.582 79.4996 133 75.9179 133 71.4996L133 2.00001"
            stroke="url(#paint1_linear_2455_59167)"
            stroke-width="3"
            stroke-linecap="round"
            stroke-dasharray="10 10"
        >
            <animate attributeName="stroke-dashoffset" from="0" to="-20" dur="0.5s" repeatCount="indefinite" />
        </path>
        <defs>
            <linearGradient
                id="paint0_linear_2455_59167"
                x1="2"
                y1="2"
                x2="112"
                y2="2.00001"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#5F937D" />
                <stop offset="1" stop-color="#6CB691" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_2455_59167"
                x1="243"
                y1="2"
                x2="133"
                y2="2.00001"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#5F937D" />
                <stop offset="1" stop-color="#6CB691" />
            </linearGradient>
        </defs>
    </svg>
)

export const ThirdTabFirstAnimation = (
    <svg width="245" height="179" viewBox="0 0 245 179" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M122.5 2V177"
            stroke="url(#paint0_linear_2455_59167)"
            stroke-width="3"
            stroke-linecap="round"
            stroke-dasharray="10 10"
        >
            <animate attributeName="stroke-dashoffset" from="0" to="20" dur="0.5s" repeatCount="indefinite" />
        </path>
        <path d="M2 89.5L243 89.5" stroke="#D8D8D8" stroke-width="3" stroke-linecap="round" />
        <path
            d="M1.99999 79.4996L104 79.4996C108.418 79.4996 112 75.9179 112 71.4996L112 2.00001"
            stroke="#D8D8D8"
            stroke-width="3"
            stroke-linecap="round"
        />
        <path
            d="M1.99999 99.5002L104 99.5001C108.418 99.5001 112 103.082 112 107.5L112 177"
            stroke="#D8D8D8"
            stroke-width="3"
            stroke-linecap="round"
        />
        <path
            d="M243 99.5002L141 99.5001C136.582 99.5001 133 103.082 133 107.5L133 177"
            stroke="#D8D8D8"
            stroke-width="3"
            stroke-linecap="round"
        />
        <path
            d="M243 79.4996L141 79.4996C136.582 79.4996 133 75.9179 133 71.4996L133 2.00001"
            stroke="url(#paint1_linear_2455_59167)"
            stroke-width="3"
            stroke-linecap="round"
            stroke-dasharray="10 10"
        >
            <animate attributeName="stroke-dashoffset" from="0" to="-20" dur="0.5s" repeatCount="indefinite" />
        </path>
        <defs>
            <linearGradient
                id="paint0_linear_2455_59167"
                x1="122.5"
                y1="2"
                x2="123.5"
                y2="2"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#5F937D" />
                <stop offset="1" stop-color="#6CB691" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_2455_59167"
                x1="243"
                y1="2"
                x2="133"
                y2="2.00001"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#5F937D" />
                <stop offset="1" stop-color="#6CB691" />
            </linearGradient>
        </defs>
    </svg>
)

export const FirstTabSecondAnimation = (
    <svg width="245" height="179" viewBox="0 0 245 188" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M122.5 2V177" stroke="#D8D8D8" stroke-width="3" stroke-linecap="round" />
        <path
            d="M2 89.5L243 89.5"
            stroke="url(#paint0_linear_2455_59167)"
            stroke-width="3"
            stroke-linecap="round"
            stroke-dasharray="10 10"
        >
            <animate attributeName="stroke-dashoffset" from="0" to="-20" dur="0.5s" repeatCount="indefinite" />
        </path>
        <path
            d="M1.99999 79.4996L104 79.4996C108.418 79.4996 112 75.9179 112 71.4996L112 2.00001"
            stroke="url(#paint1_linear_2455_59167)"
            stroke-width="3"
            stroke-linecap="round"
            stroke-dasharray="10 10"
        >
            <animate attributeName="stroke-dashoffset" from="0" to="-20" dur="0.5s" repeatCount="indefinite" />
        </path>
        <path
            d="M1.99999 99.5002L104 99.5001C108.418 99.5001 112 103.082 112 107.5L112 177"
            stroke="url(#paint2_linear_2455_59167)"
            stroke-width="3"
            stroke-linecap="round"
            stroke-dasharray="10 10"
        >
            <animate attributeName="stroke-dashoffset" from="0" to="-20" dur="0.5s" repeatCount="indefinite" />
        </path>
        <path
            d="M1.99999 109L30 109C34.4183 109 38 112.582 38 117L38 186"
            stroke="url(#paint3_linear_2455_59167)"
            stroke-width="3"
            stroke-linecap="round"
            stroke-dasharray="10 10"
        >
            <animate attributeName="stroke-dashoffset" from="0" to="-20" dur="0.5s" repeatCount="indefinite" />
        </path>
        <path
            d="M243 99.5002L141 99.5001C136.582 99.5001 133 103.082 133 107.5L133 177"
            stroke="#D8D8D8"
            stroke-width="3"
            stroke-linecap="round"
        />
        <path
            d="M243 79.4996L141 79.4996C136.582 79.4996 133 75.9179 133 71.4996L133 2.00001"
            stroke="#D8D8D8"
            stroke-width="3"
            stroke-linecap="round"
        />
        <defs>
            <linearGradient
                id="paint0_linear_2455_59167"
                x1="2"
                y1="89.5"
                x2="243"
                y2="89.5"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#5F937D" />
                <stop offset="1" stop-color="#6CB691" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_2455_59167"
                x1="2"
                y1="2"
                x2="112"
                y2="2.00001"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#5F937D" />
                <stop offset="1" stop-color="#6CB691" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_2455_59167"
                x1="2"
                y1="177"
                x2="112"
                y2="177"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#5F937D" />
                <stop offset="1" stop-color="#6CB691" />
            </linearGradient>
            <linearGradient
                id="paint3_linear_2455_59167"
                x1="2"
                y1="186"
                x2="38"
                y2="186"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#5F937D" />
                <stop offset="1" stop-color="#6CB691" />
            </linearGradient>
        </defs>
    </svg>
)

export const SecondTabSecondAnimation = (
    <svg width="245" height="179" viewBox="0 0 245 179" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M122.5 2V177" stroke="#D8D8D8" stroke-width="3" stroke-linecap="round" />
        <path d="M2 89.5L243 89.5" stroke="#D8D8D8" stroke-width="3" stroke-linecap="round" />
        <path
            d="M1.99999 79.4996L104 79.4996C108.418 79.4996 112 75.9179 112 71.4996L112 2.00001"
            stroke="url(#paint0_linear_2455_59167)"
            stroke-width="3"
            stroke-linecap="round"
            stroke-dasharray="10 10"
        >
            <animate attributeName="stroke-dashoffset" from="0" to="-20" dur="0.5s" repeatCount="indefinite" />
        </path>
        <path
            d="M1.99999 99.5002L104 99.5001C108.418 99.5001 112 103.082 112 107.5L112 177"
            stroke="#D8D8D8"
            stroke-width="3"
            stroke-linecap="round"
        />
        <path
            d="M243 99.5002L141 99.5001C136.582 99.5001 133 103.082 133 107.5L133 177"
            stroke="#D8D8D8"
            stroke-width="3"
            stroke-linecap="round"
        />
        <path
            d="M243 79.4996L141 79.4996C136.582 79.4996 133 75.9179 133 71.4996L133 2.00001"
            stroke="url(#paint1_linear_2455_59167)"
            stroke-width="3"
            stroke-linecap="round"
            stroke-dasharray="10 10"
        >
            <animate attributeName="stroke-dashoffset" from="0" to="-20" dur="0.5s" repeatCount="indefinite" />
        </path>
        <defs>
            <linearGradient
                id="paint0_linear_2455_59167"
                x1="2"
                y1="2"
                x2="112"
                y2="2.00001"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#5F937D" />
                <stop offset="1" stop-color="#6CB691" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_2455_59167"
                x1="243"
                y1="2"
                x2="133"
                y2="2.00001"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#5F937D" />
                <stop offset="1" stop-color="#6CB691" />
            </linearGradient>
        </defs>
    </svg>
)
export const ThirdTabSecondAnimation = (
    <svg width="245" height="179" viewBox="0 0 245 179" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M122.5 2V177"
            stroke="url(#paint0_linear_2455_59167)"
            stroke-width="3"
            stroke-linecap="round"
            stroke-dasharray="10 10"
        >
            <animate attributeName="stroke-dashoffset" from="0" to="20" dur="0.5s" repeatCount="indefinite" />
        </path>
        <path d="M2 89.5L243 89.5" stroke="#D8D8D8" stroke-width="3" stroke-linecap="round" />
        <path
            d="M1.99999 79.4996L104 79.4996C108.418 79.4996 112 75.9179 112 71.4996L112 2.00001"
            stroke="#D8D8D8"
            stroke-width="3"
            stroke-linecap="round"
        />
        <path
            d="M1.99999 99.5002L104 99.5001C108.418 99.5001 112 103.082 112 107.5L112 177"
            stroke="#D8D8D8"
            stroke-width="3"
            stroke-linecap="round"
        />
        <path
            d="M243 99.5002L141 99.5001C136.582 99.5001 133 103.082 133 107.5L133 177"
            stroke="#D8D8D8"
            stroke-width="3"
            stroke-linecap="round"
        />
        <path
            d="M243 79.4996L141 79.4996C136.582 79.4996 133 75.9179 133 71.4996L133 2.00001"
            stroke="url(#paint1_linear_2455_59167)"
            stroke-width="3"
            stroke-linecap="round"
            stroke-dasharray="10 10"
        >
            <animate attributeName="stroke-dashoffset" from="0" to="-20" dur="0.5s" repeatCount="indefinite" />
        </path>
        <defs>
            <linearGradient
                id="paint0_linear_2455_59167"
                x1="122.5"
                y1="2"
                x2="123.5"
                y2="2"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#5F937D" />
                <stop offset="1" stop-color="#6CB691" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_2455_59167"
                x1="243"
                y1="2"
                x2="133"
                y2="2.00001"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#5F937D" />
                <stop offset="1" stop-color="#6CB691" />
            </linearGradient>
        </defs>
    </svg>
)

export const FirstTabThirdAnimation = (
    <svg width="245" height="91" viewBox="0 0 245 91" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2 89.5L243 89.5"
            stroke="url(#paint0_linear_2455_59167)"
            stroke-width="3"
            stroke-linecap="round"
            stroke-dasharray="10 10"
        >
            <animate attributeName="stroke-dashoffset" from="0" to="-20" dur="0.5s" repeatCount="indefinite" />
        </path>
        <path
            d="M1.99999 79.4996L104 79.4996C108.418 79.4996 112 75.9179 112 71.4996L112 2.00001"
            stroke="url(#paint1_linear_2455_59167)"
            stroke-width="3"
            stroke-linecap="round"
            stroke-dasharray="10 10"
        >
            <animate attributeName="stroke-dashoffset" from="0" to="-20" dur="0.5s" repeatCount="indefinite" />
        </path>
        <path
            d="M243 79.4996L141 79.4996C136.582 79.4996 133 75.9179 133 71.4996L133 2.00001"
            stroke="#D8D8D8"
            stroke-width="3"
            stroke-linecap="round"
        />
        <defs>
            <linearGradient
                id="paint0_linear_2455_59167"
                x1="2"
                y1="89.5"
                x2="243"
                y2="89.5"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#5F937D" />
                <stop offset="1" stop-color="#6CB691" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_2455_59167"
                x1="2"
                y1="2"
                x2="112"
                y2="2.00001"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#5F937D" />
                <stop offset="1" stop-color="#6CB691" />
            </linearGradient>
        </defs>
    </svg>
)

export const SecondTabThirdAnimation = (
    <svg width="245" height="91" viewBox="0 0 245 91" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 89.5L243 89.5" stroke="#D8D8D8" stroke-width="3" stroke-linecap="round" />
        <path
            d="M1.99999 79.4996L104 79.4996C108.418 79.4996 112 75.9179 112 71.4996L112 2.00001"
            stroke="url(#paint0_linear_2455_59167)"
            stroke-width="3"
            stroke-linecap="round"
            stroke-dasharray="10 10"
        >
            <animate attributeName="stroke-dashoffset" from="0" to="-20" dur="0.5s" repeatCount="indefinite" />
        </path>
        <path
            d="M243 79.4996L141 79.4996C136.582 79.4996 133 75.9179 133 71.4996L133 2.00001"
            stroke="url(#paint1_linear_2455_59167)"
            stroke-width="3"
            stroke-linecap="round"
            stroke-dasharray="10 10"
        >
            <animate attributeName="stroke-dashoffset" from="0" to="-20" dur="0.5s" repeatCount="indefinite" />
        </path>
        <defs>
            <linearGradient
                id="paint0_linear_2455_59167"
                x1="2"
                y1="2"
                x2="112"
                y2="2.00001"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#5F937D" />
                <stop offset="1" stop-color="#6CB691" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_2455_59167"
                x1="243"
                y1="2"
                x2="133"
                y2="2.00001"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#5F937D" />
                <stop offset="1" stop-color="#6CB691" />
            </linearGradient>
        </defs>
    </svg>
)

export const ThirdTabThirdAnimation = (
    <svg width="245" height="91" viewBox="0 0 245 91" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 89.5L243 89.5" stroke="#D8D8D8" stroke-width="3" stroke-linecap="round" />
        <path
            d="M1.99999 79.4996L104 79.4996C108.418 79.4996 112 75.9179 112 71.4996L112 2.00001"
            stroke="#D8D8D8"
            stroke-width="3"
            stroke-linecap="round"
        />
        <path
            d="M243 79.4996L141 79.4996C136.582 79.4996 133 75.9179 133 71.4996L133 2.00001"
            stroke="url(#paint0_linear_2455_59167)"
            stroke-width="3"
            stroke-linecap="round"
            stroke-dasharray="10 10"
        >
            <animate attributeName="stroke-dashoffset" from="0" to="20" dur="0.5s" repeatCount="indefinite" />
        </path>
        <defs>
            <linearGradient
                id="paint0_linear_2455_59167"
                x1="243"
                y1="2"
                x2="133"
                y2="2.00001"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#5F937D" />
                <stop offset="1" stop-color="#6CB691" />
            </linearGradient>
        </defs>
    </svg>
)
