import {FinancialImpactChart} from './financial-impact-chart/FinancialImpactChart'
import {FinancialImpactHeader} from './financial-impact-header/FinancialImpactHeader'
import {StyledFinancialImpactRoot} from './style'
import {QuoteCalculationsType} from '@/features/quote/types.ts'

export const FinancialImpact = ({quoteCalculationsData}: {quoteCalculationsData: QuoteCalculationsType}) => {
    return (
        <StyledFinancialImpactRoot direction="column" align="center" gap={4}>
            <FinancialImpactHeader savingDifference={quoteCalculationsData.savingDifference} />
            <FinancialImpactChart solarFinancials={quoteCalculationsData.solarFinancials} />
        </StyledFinancialImpactRoot>
    )
}
