import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

type StyledInputWrapperProps = {
    $hasError: boolean
}

export const StyledInputWrapper = styled(Flexbox)<StyledInputWrapperProps>`
    ${({$hasError, theme: {palette, shadows}}) => css`
        position: relative;
        border: 2px solid ${palette.primary[700]};
        background-color: ${palette.primary[950]};
        box-shadow: ${shadows.xs};
        border-radius: 8px;
        width: 100%;
        cursor: pointer;
        outline: none;

        &:has(input:focus) {
            border-color: ${palette.primary[400]};
        }

        &:has(input:disabled) {
            opacity: 0.5;
            cursor: not-allowed;
        }

        ${$hasError &&
        css`
            color: ${palette.danger[400]};
            border-color: ${palette.danger[400]};

            &:has(input:focus) {
                border-color: ${palette.danger[400]};
            }
        `};
    `}
`
