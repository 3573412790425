import {ProjectSchema} from '@/features/project/services/project.schema'
import {SystemConfigurationSchema} from '@/features/system-configuration/services/systemConfiguration.schema'
import {z} from 'zod'

export const pivotSchema = z.object({
    pivot_quote_id: z.string().uuid(),
    pivot_product_id: z.string().uuid(),
    pivot_quantity: z.number().int(),
    pivot_unit_price: z.string(),
    pivot_final_price: z.string()
})

export const QuoteProductSchema = z.object({
    deletedAt: z.string().nullable(),
    id: z.string().uuid(),
    code: z.string(),
    name: z.string(),
    slug: z.string(),
    categoryId: z.string().uuid(),
    price: z.string(),
    finalPrice: z.string(),
    available: z.number().int(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    quantity: z.string().optional().nullable(),
    pivot: pivotSchema,
    attributes: z.object({
        description: z.string(),
        minimumQuantity: z.string(),
        power: z.string(),
        volt: z.string(),
        section: z.string().optional()
    }),
    category: z.string()
})

export const QuoteSchema = z.object({
    createdAt: z.string().datetime(),
    deletedAt: z.string().nullable(),
    expiresAt: z.string().datetime(),
    id: z.string().uuid(),
    projectSystem: SystemConfigurationSchema,
    products: z.array(QuoteProductSchema),
    project: ProjectSchema,
    projectSystemId: z.string().uuid(),
    status: z.enum(['draft', 'active', 'inactive']),
    updatedAt: z.string().datetime(),
    effectiveAccumulation: z.number(),
    quoteNumber: z.string()
})

export const QuoteCalculationsSchema = z.object({
    annualOffset: z.number(),
    id: z.string(),
    quoteFinalPrice: z.number(),
    systemSize: z.number(),
    estimatedAnnualSavings: z.number(),
    effectiveAccumulation: z.number(),
    annualOutput: z.number(),

    monthlyAverageSpent: z.object({
        old: z.number(),
        new: z.number()
    }),
    cumulativeSavings: z
        .object({
            annualCompensation: z.number(),
            annualSaving: z.number(),
            year: z.number()
        })
        .array(),

    consumptions: z
        .object({
            month: z.number(),
            value: z.number()
        })
        .array(),
    production: z
        .object({
            month: z.number(),
            value: z.number()
        })
        .array(),

    periodSavingsData: z
        .object({
            billNew: z.number(),
            billOld: z.number(),
            consumption: z.number(),
            estimatedSaving: z.number(),
            period: z.string(),
            periodCompensation: z.number(),
            production: z.number()
        })
        .array(),

    savingDifference: z.object({
        first: z.number(),
        second: z.number(),
        result: z.number()
    }),

    solarFinancials: z
        .object({
            initialInvestment: z.number(),
            paybackPeriod: z.number(),
            returnOnInvestment: z.number(),
            cumulativeNetImpact: z.number()
        })
        .array(),

    environmentalBenefits: z.object({
        carKilometersAvoided: z.number(),
        co2AvoidedPercentage: z.number(),
        co2AvoidedTonnes: z.number(),
        flightsAvoided: z.number(),
        treesPlanted: z.number()
    })
})
export const ChangeQuotePriceResponseSchema = z.object({
    id: z.string().uuid(),
    status: z.string(),
    projectSystemId: z.string().uuid()
})

export const UpdateProductSchema = z.object({
    productId: z.string().uuid(),
    finalPrice: z.string()
})
export const ChangeQuotePricePayloadSchema = z.object({
    products: z.array(UpdateProductSchema)
})
