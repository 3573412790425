import {PageSchema} from '@/utilities/common.schema'
import {z} from 'zod'

export enum ComponentCategoriesE {
    System = 'system',
    CombinerBox = 'Combiner-Box',
    Storage = 'Storage',
    Other = 'Other',
    Ballast = 'Ballast',
    Furniture = 'Furniture',
    WallBattery = 'Wall-Battery',
    Optimizer = 'Optimizer',
    Panel = 'Panel',
    Fixing = 'Fixing',
    Rail = 'Rail',
    Inverter = 'Inverter',
    Battery = 'Battery',
    ScrewAndBracket = 'screw-and-bracket',
    Triangles = 'Triangles',
    Cables = 'Cables',
    Connectors = 'Connectors',
    PowerDistributionPanelsAC = 'Power-Distribution-Panels-AC',
    PowerDistributionPanelsDC = 'Power-Distribution-Panels-DC',
    PowerDistributionPanelsACDC = 'power-distribution-panels-acdc',
    Splice = 'Splice',
    CentralEndClamp = 'central-clamp',
    PowerDistributionPanelsOther = 'Power-Distribution-Panels-Other',
    PowerAndElectricalSupplies = 'Power-and-Electrical-Supplies',
    EndClamp = 'End-clamp',
    FasteningKit = 'fastening-kit',
    Mat = 'mat',
    RailClamp = 'rail-clamp',
    Kit = 'kit'
}

export enum AttributeKeysE {
    Structure = 'structure',
    Connection = 'connection',
    Operation = 'operation',
    Power = 'power',
    Section = 'section',
    Length = 'length',
    Width = 'width',
    Type = 'type',
    Code = 'code'
}

export enum AttributeOperatorsE {
    LessThan = '<',
    GreaterThan = '>',
    Equals = '=',
    LessThanOrEqual = '<=',
    GreaterThanOrEqual = '>='
}
export enum AttributePanelType {
    Black = 'black'
}

const AttributeKeysSchema = z.nativeEnum(AttributeKeysE)
const AttributeOperatorsSchema = z.nativeEnum(AttributeOperatorsE)
const ComponentCategoriesSchema = z.nativeEnum(ComponentCategoriesE)

const ParentComponentCategorySchema = z.object({
    deletedAt: z.string().nullable(),
    id: z.string(),
    name: z.string(),
    slug: ComponentCategoriesSchema,
    description: z.string().nullable(),
    parentId: z.string().nullable(),
    createdAt: z.string(),
    updatedAt: z.string()
})

export const ComponentCategorySchema = z.object({
    id: z.string(),
    name: z.string(),
    slug: ComponentCategoriesSchema,
    count: z.number().optional(),
    description: z.string().nullable(),
    createdAt: z.string(),
    updatedAt: z.string(),
    parentCategory: ParentComponentCategorySchema.nullable(),
    projectsCount: z.number().optional(), //TODO ask BE to add
    discount: z
        .object({
            discountRule: z
                .object({
                    name: z.string(),
                    id: z.string()
                })
                .nullish()
        })
        .nullish()
})

export const ComponentAttributeSchema = z.object({
    key: AttributeKeysSchema,
    operator: AttributeOperatorsSchema,
    value: z.union([z.string(), z.number()]).optional()
})

const PivotSchema = z.object({
    pivot_product_id: z.string(),
    pivot_attribute_id: z.string(),
    pivot_value_string: z.string().nullable(),
    pivot_value_number: z.string().nullable()
})

const AttributeSchema = z.object({
    deletedAt: z.string().nullable(),
    id: z.string(),
    name: z.string(),
    slug: AttributeKeysSchema,
    createdAt: z.string(),
    updatedAt: z.string(),
    pivot: PivotSchema
})

export const ComponentSchema = z.object({
    id: z.string(),
    code: z.string(),
    name: z.string(),
    slug: z.string(),
    categoryId: z.string(),
    priceList: z.number(),
    price: z.string(),
    finalPrice: z.string(),
    available: z.number(),
    createdAt: z.string(),
    updatedAt: z.string(),
    attributes: z.array(AttributeSchema),
    logoImagePath: z.string().optional(),
    search: z.string().optional(),
    discount: z
        .object({
            discountRule: z
                .object({
                    name: z.string(),
                    id: z.string()
                })
                .optional()
        })
        .optional()
})

export const ComponentListResponseSchema = PageSchema(ComponentSchema)
