import {useMutation} from '@tanstack/react-query'
import {PROFILE_MUTATION_KEYS} from './keys'
import {PROFILE_API} from '../services/profile.http'
import {ChangePasswordPayloadType} from '../types'

type useChangePasswordProps = {
    onSuccess: () => void
}

export const useChangePassword = ({onSuccess}: useChangePasswordProps) => {
    return useMutation({
        mutationKey: [PROFILE_MUTATION_KEYS.changePassword],
        mutationFn: (body: ChangePasswordPayloadType) => PROFILE_API.changePassword(body),
        onSuccess: () => {
            onSuccess?.()
        }
    })
}
