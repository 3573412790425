import {useTranslation} from 'react-i18next'
import {
    StyleContainerOperationSystem,
    StyledTitleOperationSystem
} from '@/features/quote/components/operation-system/style.ts'
import Tabs from '@/features/quote/components/operation-system/Tabs.tsx'
import BaseIcon from '@/features/quote/components/operation-system/BaseIcon.tsx'
import {
    FirstTabFirstAnimation,
    FirstTabSecondAnimation,
    FirstTabThirdAnimation,
    SecondTabFirstAnimation,
    SecondTabSecondAnimation,
    SecondTabThirdAnimation,
    ThirdTabFirstAnimation,
    ThirdTabSecondAnimation,
    ThirdTabThirdAnimation
} from '@/features/quote/components/operation-system/Animations.tsx'
import {QuoteCalculationsType, QuoteType} from '@/features/quote/types.ts'

type AnimationMap = {
    '1': JSX.Element
    '2': JSX.Element
    '3': JSX.Element
}

export const OperationSystem = ({
    quoteCalculationsData,
    quoteData
}: {
    quoteCalculationsData: QuoteCalculationsType
    quoteData: QuoteType
}) => {
    const {t} = useTranslation()

    const animationMap: {
        batteryOnly: AnimationMap
        batteryAndStation: AnimationMap
        noBattery: AnimationMap
    } = {
        batteryOnly: {
            '1': FirstTabFirstAnimation,
            '2': SecondTabFirstAnimation,
            '3': ThirdTabFirstAnimation
        },
        batteryAndStation: {
            '1': FirstTabSecondAnimation,
            '2': SecondTabSecondAnimation,
            '3': ThirdTabSecondAnimation
        },
        noBattery: {
            '1': FirstTabThirdAnimation,
            '2': SecondTabThirdAnimation,
            '3': ThirdTabThirdAnimation
        }
    }

    const conditionsChildren = (tab: number) => {
        const tabKey = tab.toString() as keyof AnimationMap

        if (quoteData?.projectSystem?.batteryProductId && quoteData?.projectSystem.chargingStationProductId) {
            return animationMap.batteryAndStation[tabKey]
        }
        if (quoteData?.projectSystem?.batteryProductId || quoteData.projectSystem.type === 'cerbero') {
            return animationMap.batteryOnly[tabKey]
        }
        return animationMap.noBattery[tabKey]
    }

    const tabData = [
        {
            title: t('quote:operation_system:tabs:tab1'),
            content: (
                <BaseIcon quoteCalculationsData={quoteCalculationsData} quoteData={quoteData?.projectSystem}>
                    {conditionsChildren(1)}
                </BaseIcon>
            )
        },
        {
            title: t('quote:operation_system:tabs:tab2'),
            content: (
                <BaseIcon quoteCalculationsData={quoteCalculationsData} quoteData={quoteData?.projectSystem}>
                    {conditionsChildren(2)}
                </BaseIcon>
            )
        },
        {
            title: t('quote:operation_system:tabs:tab3'),
            content: (
                <BaseIcon quoteCalculationsData={quoteCalculationsData} quoteData={quoteData?.projectSystem}>
                    {conditionsChildren(3)}
                </BaseIcon>
            )
        }
    ]

    return (
        <StyleContainerOperationSystem direction={'column'} gap={4}>
            <StyledTitleOperationSystem>{t('quote:operation_system:title')}</StyledTitleOperationSystem>
            <Tabs tabs={tabData} />
        </StyleContainerOperationSystem>
    )
}
