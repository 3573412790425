import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import backgroundImage from '../../../../assets/images/quote_background.png'

export const StyledQuotePageWrapper = styled.div(
    ({theme: {palette, spacing}}) => css`
        background: ${palette.neutral['white']} !important;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: auto;
        gap: ${spacing * 14}px;
    `
)

export const StyledPdfQuotePageWrapper = styled.div(
    ({theme: {palette, spacing}}) => css`
        background: ${palette.neutral['white']} !important;
        min-height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: auto;
        gap: ${spacing * 14}px;

        @media print {
            #header,
            #footer {
                position: fixed;
                left: 0;
                right: 0;
            }
            #nav {
                display: none !important;
            }
            & > * {
                page-break-inside: avoid;
            }
            .section {
                page-break-before: always;
            }
        }
    `
)

export const StyledProposalRow = styled.div(
    ({theme: {spacing}}) => css`
        border-radius: 56px;
        width: 100%;
        padding: ${spacing * 14}px;

        background-image: url(${backgroundImage});
        background-size: cover;
        background-position: right;
        background-repeat: no-repeat;
    `
)

export const StyledQuoteData = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 7}px 0;
    `
)

export const StyledFirstRowQuoteTitle = styled.h1(
    ({theme: {palette, typography}}) => css`
        color: ${palette.neutral[50]};
        ${typography.displayMd};
        font-weight: 500;
    `
)

export const StyledQuoteNumber = styled.p(
    ({theme: {palette, typography}}) => css`
        color: ${palette.neutral[50]};
        ${typography.displayXs};
        font-weight: 500;
    `
)

export const StyledQuoteDates = styled.p(
    ({theme: {palette, typography}}) => css`
        color: ${palette.neutral[50]};
        ${typography.textMd};
    `
)

export const StyledBillingData = styled.div(
    ({theme: {palette, spacing}}) => css`
        border-top: 1px solid ${palette.neutral[50]};
        border-bottom: 1px solid ${palette.neutral[50]};
        padding: ${spacing * 7}px 0;

        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: flex-start;
    `
)

export const StyledThanks = styled.div(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 7}px 0 ${spacing * 14}px 0;
    `
)

export const StyledQuoteSummary = styled.div(
    ({theme: {palette, spacing}}) => css`
        padding: ${spacing * 8}px ${spacing * 10}px;

        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: center;
        border-radius: 24px;
        background: ${palette.neutral[50]};
        color: ${palette.neutral[900]};
    `
)

export const StyledDataTitle = styled.p(
    ({theme: {typography}}) => css`
        ${typography.textSm};
    `
)

export const StyledQuoteName = styled.p(
    ({theme: {typography}}) => css`
        ${typography.textXs};
        font-weight: 500;
    `
)

export const StyledContacts = styled.p(
    ({theme: {typography, palette}}) => css`
        ${typography.textSm};
        color: ${palette.primary[300]};
    `
)
export const StyledSummaryNumber = styled.p(
    ({theme: {typography, palette}}) => css`
        ${typography.displayMd};
        color: ${palette.primary[950]};
        font-weight: 600;
    `
)

export const StyledSummaryDescription = styled.p(
    ({theme: {typography, palette}}) => css`
        ${typography.textSm};
        font-weight: 500;
        color: ${palette.primary[700]};
    `
)

export const StyledQRCodeWrapper = styled.div(
    ({theme: {spacing, palette, typography}}) => css`
        position: relative; /* Ensures the ::before overlay aligns with the wrapper */
        width: 340px;
        height: 140px;
        padding: ${spacing * 9}px;
        border-radius: 24px;
        display: flex;
        gap: ${spacing * 6}px;
        align-items: center;

        /* Pseudo-element for background and blur */
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(255, 255, 255, 0.5); /* Semi-transparent white */
            backdrop-filter: blur(17px); /* Apply the blur effect */
            -webkit-backdrop-filter: blur(17px); /* For Safari support */
            border-radius: 24px; /* Match the parent’s border radius */
            z-index: 0; /* Keep it behind the content */
        }

        /* Ensuring content sits above the blur */
        & > * {
            position: relative;
            z-index: 1;
        }

        /* QR Code styling */
        & svg {
            width: 76px;
            height: 76px;
        }

        /* Text styling */
        & p {
            ${typography.textXs};
            font-weight: 500;
            color: ${palette.neutral[950]};
        }
    `
)

export const StyledQuoteID = styled.p(
    ({theme: {palette, typography}}) => css`
        color: ${palette.neutral.white};
        ${typography.displayXs};
        font-weight: 500;
    `
)
