import {useTranslation} from 'react-i18next'
import {
    StyleContainerOperationSystem,
    StyledIconRowContainer,
    StyledTitleOperationSystem
} from '@/features/quote/components/operation-system/style.ts'
import {
    FirstTabFirstAnimation,
    FirstTabSecondAnimation,
    FirstTabThirdAnimation,
    SecondTabFirstAnimation,
    SecondTabSecondAnimation,
    SecondTabThirdAnimation,
    ThirdTabFirstAnimation,
    ThirdTabSecondAnimation,
    ThirdTabThirdAnimation
} from '@/features/quote/components/operation-system/Animations.tsx'
import {QuoteCalculationsType, QuoteType} from '@/features/quote/types.ts'
import BaseIconPdf from './BaseIconPdf'
import {AcceptQuote} from './AcceptQuote'

type AnimationMap = {
    '1': JSX.Element
    '2': JSX.Element
    '3': JSX.Element
}

export const OperationSystemPdf = ({
    quoteCalculationsData,
    quoteData
}: {
    quoteCalculationsData: QuoteCalculationsType
    quoteData: QuoteType
}) => {
    const {t} = useTranslation()

    const animationMap: {
        batteryOnly: AnimationMap
        batteryAndStation: AnimationMap
        noBattery: AnimationMap
    } = {
        batteryOnly: {
            '1': FirstTabFirstAnimation,
            '2': SecondTabFirstAnimation,
            '3': ThirdTabFirstAnimation
        },
        batteryAndStation: {
            '1': FirstTabSecondAnimation,
            '2': SecondTabSecondAnimation,
            '3': ThirdTabSecondAnimation
        },
        noBattery: {
            '1': FirstTabThirdAnimation,
            '2': SecondTabThirdAnimation,
            '3': ThirdTabThirdAnimation
        }
    }

    const conditionsChildren = (tab: number) => {
        const tabKey = tab.toString() as keyof AnimationMap

        if (quoteData?.projectSystem?.batteryProductId && quoteData?.projectSystem.chargingStationProductId) {
            return animationMap.batteryAndStation[tabKey]
        }
        if (quoteData?.projectSystem?.batteryProductId || quoteData.projectSystem.type === 'cerbero') {
            return animationMap.batteryOnly[tabKey]
        }
        return animationMap.noBattery[tabKey]
    }

    return (
        <>
            <StyleContainerOperationSystem direction={'column'} gap={4}>
                <StyledTitleOperationSystem>{t('quote:operation_system:title')}</StyledTitleOperationSystem>
                <StyledIconRowContainer>
                    <BaseIconPdf
                        quoteCalculationsData={quoteCalculationsData}
                        quoteData={quoteData?.projectSystem}
                        title={t('quote:operation_system:tabs:tab1')}
                    >
                        {conditionsChildren(1)}
                    </BaseIconPdf>
                    <BaseIconPdf
                        quoteCalculationsData={quoteCalculationsData}
                        quoteData={quoteData?.projectSystem}
                        title={t('quote:operation_system:tabs:tab2')}
                    >
                        {conditionsChildren(2)}
                    </BaseIconPdf>
                    <BaseIconPdf
                        quoteCalculationsData={quoteCalculationsData}
                        quoteData={quoteData?.projectSystem}
                        title={t('quote:operation_system:tabs:tab3')}
                    >
                        {conditionsChildren(3)}
                    </BaseIconPdf>
                </StyledIconRowContainer>
                <AcceptQuote />
            </StyleContainerOperationSystem>
            {/*  <StyledPdfFooter>
                <StyledFooterLogoWrapper>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <SunProIcon />
                        <StyledAryaLogo />
                         <div>
                         <img src={arya_logo} />
                         </div>
                    </div>
                </StyledFooterLogoWrapper>
            </StyledPdfFooter>*/}
        </>
    )
}
